import {
  FeatureToggleList,
  FEATURE_TOGGLE_ENVS,
} from '../domain/featureToggleEnv';

export const _shouldShowFeature = (featureKey: FeatureToggleList): boolean => {
  if (
    FEATURE_TOGGLE_ENVS[featureKey] === 'false' ||
    FEATURE_TOGGLE_ENVS[featureKey] === 'FALSE'
  ) {
    return false;
  }

  return !!FEATURE_TOGGLE_ENVS[featureKey];
};

export function directCoachService() {
  return {
    shouldShowFeature: (featureKey: FeatureToggleList): boolean =>
      _shouldShowFeature(featureKey),
  };
}
