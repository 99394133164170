import { NextWebVitalsMetric } from 'next/app';
import { User } from '../domain/user';
import { ClassFragment } from '../typescript/generated/codegen';
import { loggingAdapter } from './loggingAdapter';
import { GoogleAnalyticsService, LoggingService } from './ports';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

type GtagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

const _gtagEvent = ({ action, category, label, value }: GtagEvent) => {
  window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
};

const _login = (user: User): void => {
  _gtagEvent({
    action: 'user_login',
    category: user?.id,
  });
};

const _logout = (
  user: User,
  deps: {
    logging: LoggingService;
  }
) => {
  const { logging } = deps;

  if (user.id) {
    _gtagEvent({
      action: 'user_logout',
      category: user.id,
    });
  } else {
    logging.error({
      caller: 'analytics._logout',
      message: 'no user data available',
    });
  }
};

const _signup = (
  user: User,
  deps: {
    logging: LoggingService;
  }
) => {
  const { logging } = deps;

  if (user.id) {
    _gtagEvent({
      action: 'user_signup',
      category: user.id,
    });
  } else {
    logging.error({
      caller: 'analytics._signup',
      message: 'no user data available',
    });
  }
};

const _verifyEmailFail = (user: User) => {
  _gtagEvent({ action: 'user_verify_email_success', category: user.id });
};

const _verifyEmailSuccess = (user: User) => {
  _gtagEvent({ action: 'user_verify_email_fail', category: user.id });
};

const _subscribeSuccess = (user: User) => {
  _gtagEvent({ action: 'user_subscribe_success', category: user.id });
};

const _recordPageView = (url: string) => {
  window.gtag &&
    window.gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || '', {
      page_path: url,
    });
};

const _recordRefreshToken = () => {
  _gtagEvent({ action: 'refresh_token_success' });
};

const _recordRefreshTokenFailure = (label: string | undefined) => {
  _gtagEvent({
    action: 'refresh_token_fail',
    category: 'error',
    label,
  });
};

export const _recordVideoError = (session: ClassFragment) => {
  _gtagEvent({
    action: 'video_error',
    category: 'error',
    label: session.beid,
  });
};

export const _recordStartVideo = (session: ClassFragment) => {
  _gtagEvent({
    action: 'begin_watch_video',
    category: session.beid,
    label: `${session.teacher?.firstName} ${session.teacher?.lastName}`,
  });
};

export const _recordCouponCode = (
  code: string,
  email: string,
  value: number
) => {
  _gtagEvent({
    action: 'coupon_code',
    category: email,
    label: code,
    value: value,
  });
};

export const sendWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  window.gtag &&
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
};

export const analyticsAdapter = (): GoogleAnalyticsService => {
  const logging: LoggingService = loggingAdapter();

  return {
    login: (user: User): void => _login(user),
    logout: (user: User): void => _logout(user, { logging }),
    signup: (user: User): void => _signup(user, { logging }),
    verifyEmailSuccess: (user: User): void => _verifyEmailSuccess(user),
    verifyEmailFail: (user: User): void => _verifyEmailFail(user),
    subscribeSuccess: (user: User): void => _subscribeSuccess(user),
    recordPageView: (url: string): void => _recordPageView(url),
    recordRefreshTokenSuccess: (): void => _recordRefreshToken(),
    recordRefreshTokenFailure: (label: string | undefined): void =>
      _recordRefreshTokenFailure(label),
    recordSessionVideoError: (session: ClassFragment): void =>
      _recordVideoError(session),
    recordSessionStartVideo: (session: ClassFragment): void =>
      _recordStartVideo(session),
    recordCouponCode: (code: string, email: string, status: number) =>
      _recordCouponCode(code, email, status),
  };
};
