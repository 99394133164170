import { User, UserChecks } from '../../domain/user';
import { CheckUserResponseDTO, UserDTO } from '../backendDTOs';

export const userDTOtoUser = (incoming: UserDTO): User => {
  return {
    BMLRecord: incoming.BMLRecord,
    BMLToConvert: incoming.BMLToConvert,
    BMLToConverted: incoming.BMLToConverted,
    address: incoming.address,
    address2: incoming.address2,
    billingStatus: incoming.billingStatus,
    cType: incoming.cType,
    city: incoming.city,
    country: incoming.country,
    created_at: incoming.created_at,
    deleted_at: incoming.deleted_at,
    email: incoming.email,
    firstName: incoming.firstName,
    homePhone: incoming.homePhone,
    id: incoming.id,
    imageURL: incoming.imageURL,
    intercomCreatedAt: incoming.intercomCreatedAt,
    intercomCreatedAtString: incoming.intercomCreatedAtString,
    intercomID: incoming.intercomID,
    lastName: incoming.lastName,
    last_modified: incoming.last_modified,
    mobilePhone: incoming.mobilePhone,
    name: incoming.name,
    onlineAccess: incoming.onlineAccess,
    postalCode: incoming.postalCode,
    promoCode: incoming.promoCode,
    referral: incoming.referral,
    sourceID: incoming.sourceID,
    state: incoming.state,
    status: incoming.status,
    stripeID: incoming.stripeID,
    teacherID: incoming.teacherID,
    workPhone: incoming.workPhone,
    settings: undefined,
    locale: '',
    isLoggedIn: false,
  };
};

export const userCheckResponseDTOtoUserChecks = (
  uc: CheckUserResponseDTO
): UserChecks => {
  return {
    status: uc.status,
    videoAccess: uc.videoAccess,
    accessStatus: uc.accessStatus,
    billingModel: uc.billingModel,
    limit: uc.limit,
    available: uc.available,
    limitMessage: uc.limitMessage,
    paymentPeriodEnd: new Date(uc.paymentPeriodEndDate),
  };
};
