import React from 'react';
import AnalyticsEffects from './analyticsEffects';
import AppEffects from './appEffects';
import AuthEffects from './authEffects';
import DirectCoachEffects from './directCoachEffects';
import NotificationEffects from './notificationEffects';

interface EffectsProps {}

const Effects: React.FC<EffectsProps> = ({ children }) => {
  return (
    <AnalyticsEffects>
      <AuthEffects>
        <AppEffects>
          <NotificationEffects>
            <DirectCoachEffects>{children}</DirectCoachEffects>
          </NotificationEffects>
        </AppEffects>
      </AuthEffects>
    </AnalyticsEffects>
  );
};

export default Effects;
