import { State as Filters } from '../context/filters';
import { PricingModelId } from '../domain/billing';
import {
  ClassFragment,
  TeacherFragment,
} from '../typescript/generated/codegen';
import { BillingStatusID } from './../domain/billing';

export enum Roles {
  ADMIN = 'Admin',
  CLIENT = 'Client',
  SUPER = 'Super',
}

export type AuthResponseDTO = {
  access: string;
  bmlUser: boolean;
  refresh: string;
  roles: Roles[];
  type: Roles;
  userID: string;
  userName: string;
  intercom_hash: string;
  intercomID: string;
};

export type UserDTO = {
  BMLRecord?: boolean;
  BMLToConvert?: boolean;
  BMLToConverted?: boolean;
  address?: string;
  address2?: string;
  billingStatus?: BillingStatusID;
  cType?: 'Client';
  city?: string;
  country?: string;
  created_at?: string;
  deleted_at?: string;
  email?: string;
  firstName?: string;
  homePhone?: string;
  id?: string;
  imageURL?: string;
  intercomCreatedAt?: number;
  intercomCreatedAtString?: string;
  intercomID?: string;
  lastName?: string;
  last_modified?: string;
  mobilePhone?: string;
  name?: string;
  onlineAccess?: boolean;
  postalCode?: string;
  promoCode?: string;
  referral?: string;
  sourceID?: string;
  state?: string;
  status?: 'active' | 'inactive' | 'deleted';
  stripeID?: string;
  teacherID?: string;
  workPhone?: string;
};

export type UserResponseDTO = {
  client: UserDTO;
};

export type UserSignupRequestDTO = {
  acceptTerms: boolean;
  confirm_password: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  promoCode: string | null;
  referral: string;
  type: string;
  recaptcha: string;
};

export type UserProfileRequestDTO = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type ResetPasswordRequestDTO = {
  email: string;
  password: string;
  confirm_password: string;
  token: string;
};

export type StripeCheckoutRequestDTO = {
  priceId: string;
  coupon?: string;
  trialPeriodDays?: number;
  cancelUrl: string;
  successUrl: string;
};

export type StripeSessionResponseDTO = {
  sessionID: string;
  message?: string;
  action?: string;
};

export type CheckCouponResponseDTO = {
  CCNotRequired: boolean;
  days: string;
  message: string;
  subMessage: string;
  valid: boolean;
};

export interface Metadata {
  billingModel: string;
  limit: string;
  limitType: string;
  online: string;
  planName: string;
  ver: string;
}

export interface Product {
  active: boolean;
  attributes?: any;
  caption: string;
  created: number;
  deactivate_on?: any;
  description: string;
  id: string;
  images?: any;
  livemode: boolean;
  metadata?: any;
  name: string;
  package_dimensions?: any;
  shippable: boolean;
  statement_descriptor: string;
  type: string;
  unit_label: string;
  url: string;
  updated: number;
}

export interface Recurring {
  aggregate_usage: string;
  interval: string;
  interval_count: number;
  trial_period_days: number;
  usage_type: string;
}

export interface Price {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  deleted: boolean;
  id: string;
  livemode: boolean;
  lookup_key: string;
  metadata: Metadata;
  nickname: string;
  object: string;
  product: Product;
  recurring: Recurring;
  tiers?: any;
  tiers_mode: string;
  transform_quantity?: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface StripePricingVersion2ResponseDTO {
  price: Price[];
}

export interface ContentfulTeachersDTO {
  data: {
    teacherCollection: {
      items: TeacherFragment[];
    };
  };
}

export interface ContentfulFeatureSessionsDTO {
  data: {
    featureClassCollection: {
      items: {
        class: ClassFragment;
      }[];
    };
  };
}

export interface ContentfulSessionsDTO {
  data: {
    classCollection: {
      items: ClassFragment[];
    };
  };
}

export interface ContentfulClassCategoriesDTO {
  total: number;
  data: {
    classificationCategoriesCollection: {
      items: ContentfulClassCategories[];
    };
  };
}

export interface ContentfulClassCategories {
  classification: string;
  categories: string[];
  defaultIndex: number;
}

export interface ContentfulQueryGeneratorParams {
  filters?: Filters;
  where?: any;
  order?: any;
  limit?: number;
  returnAll?: boolean; // if true then don't return classes with publishedAt > now()
}
export type CheckUserResponseDTO = {
  status: string;
  videoAccess: boolean;
  accessStatus: string;
  lastStripe: string;
  lastStripeDT: string;
  trailEndDate: string;
  paymentPeriodStartDate: string;
  paymentPeriodEndDate: string;
  message:
    | ''
    | 'StudioAccessNotCurrent'
    | 'ConvertMembership'
    | 'MembershipCancelWarningOne'
    | 'MembershipCancelWarningTwo'
    | 'TrialEnding'
    | 'TrailEndingWithContinue'
    | 'TrailExpiredWithAccess'
    | 'MembershipExpired';
  button:
    | ''
    | 'ConvertMembership'
    | 'UpdateSubcription'
    | 'CheckSubscription'
    | 'Play'
    | 'ReactivateSubscription';
  watchButton:
    | ''
    | 'ConvertMembership'
    | 'UpdateSubcription'
    | 'CheckSubscription'
    | 'Play'
    | 'ReactivateSubscription';
  billingModel: string;
  limit: string;
  available: number;
  limitMessage:
    | ''
    | 'LimitNotExpired'
    | 'LimitToUpgrade'
    | 'LimitExpired'
    | 'LimitTopUp';
};

export enum ButtonOptionsDTO {
  ConvertMembership = 'ConvertMembership',
  UpdateSubscription = 'UpdateSubscription',
  CheckSubscription = 'CheckSubscription',
  Watch = 'Play',
  ReactivateSubscription = 'ReactivateSubscription',
}

export enum MessageLimitOptionsDTO {
  LimitNotExpired = 'LimitNotExpired',
  LimitToUpgrade = 'LimitToUpgrade',
  LimitExpired = 'LimitExpired',
  LimitTopUp = 'LimitTopUp',
}

export enum MessageOptionsDTO {
  StudioAccessNotCurrent = 'StudioAccessNotCurrent',
  ConvertMembership = 'ConvertMembership',
  MembershipCancelWarningOne = 'MembershipCancelWarningOne',
  MembershipCancelWarningTwo = 'MembershipCancelWarningTwo',
  TrailEnding = 'TrailEnding',
  TrailEndingWithContinue = 'TrailEndingWithContinue',
  ExpiredWithAccess = 'TrailExpiredWithAccess',
  Expired = 'MembershipExpired',
}

export type SessionVideoCheckResponseDTO = {
  streamURL: string;
  status: string;
  streamURLs: StreamURL[];
};

export type StreamURL = {
  timeStamp: string;
  streamURL: string;
  status: string;
};

export type ButtonText = {
  watchButton: string;
  genericButton: string;
};

export type DirectCoachBookingResponseDTO = {
  Booking: BookingDTO;

  Class: ClassDTO;

  Teacher: TeacherDTO;

  OTS: {
    id: string;
  };
};

export type BookingDTO = {
  id: string;
  startDateTime: string;
  endDateTime: string;
  duration: number;
  cancelled: boolean;
};

export type ClassDTO = {
  id: string;
  name: string;
  classification:
    | 'Yoga'
    | 'Pilates'
    | 'Meditation'
    | 'Nutrition'
    | 'Barre'
    | 'Education'
    | 'Fitness'
    | 'Mindfulness'
    | 'Music';
  imageURL: string;
  shortID: string;
};

export type TeacherDTO = {
  firstName: string;
  lastName: string;
  slug: string;
};

export type UserPrivateResponseDTO = {
  planName: PricingModelId;
};

export interface GetDCClassesResponseDTO {
  id: string;
  classID: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  duration: number;
  teacherID: string;
  teacherName: string;
  isCanceled: boolean;
  isPublic: boolean;
  maxParticipants: number;
  price: number;
  currency: string;
  visitBooked: number;
}
