import { AxiosError, AxiosInstance } from 'axios';
import { CustomError } from 'ts-custom-error';
import { isClassValid } from '../domain/class';
import {
  BookingForStudent,
  isSessionValidDC,
  SessionByClassDC,
} from '../domain/directCoach';
import { Settings } from '../domain/user';
import { sortByDateEarliestFirst } from '../libs/time';
import {
  AuthResponseDTO,
  CheckCouponResponseDTO,
  CheckUserResponseDTO,
  DirectCoachBookingResponseDTO,
  GetDCClassesResponseDTO,
  Price,
  ResetPasswordRequestDTO,
  SessionVideoCheckResponseDTO,
  StripeCheckoutRequestDTO,
  StripePricingVersion2ResponseDTO,
  StripeSessionResponseDTO,
  UserPrivateResponseDTO,
  UserProfileRequestDTO,
  UserResponseDTO,
  UserSignupRequestDTO,
} from './backendDTOs';
import getApiFetcher from './fetchers/apiFetcher';
import { loggingAdapter } from './loggingAdapter';
import {
  directCoachResponseDTOtoDomain,
  getSessionsByClassResponseDTOtoSessionByClass,
} from './mappers/directCoachMappers';
import { ApiService, LoggingService } from './ports';

export const V1_PREFIX = '/api/v1';

export class ApiAdapterError extends CustomError {
  public constructor(
    public statusCode: number,
    message?: string,
    public data?: any
  ) {
    super(message);
  }
}

export const _login = async (
  email: string,
  password: string,
  deps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<AuthResponseDTO> => {
  const { logger, fetcher } = deps;

  try {
    const response = await fetcher.post('/v1/api/user/login', {
      email: email.toLowerCase(),
      password: password,
    });

    return response.data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._login',
      message: `Failed to login: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchUser = async (
  userId: string,
  deps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<UserResponseDTO> => {
  const { logger, fetcher } = deps;

  try {
    const { data } = await fetcher.get(`/v1/api/clients/${userId}`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._fetchUser',
      message: `Failed to fetch user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchUserSettings = async (deps: {
  logger: LoggingService;
  fetcher: AxiosInstance;
}): Promise<Settings> => {
  const { logger, fetcher } = deps;

  try {
    const { data } = await fetcher.get(`/v1/api/store/settings`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._fetchUserSettings',
      message: `Failed to fetch user settings: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _saveUserSettings = async (
  userData: any,
  deps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<Settings> => {
  const { logger, fetcher } = deps;

  try {
    const { data } = await fetcher.post(`/v1/api/store/settings`, {
      ...userData,
    });

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._saveUserSettings',
      message: `Failed to update in user data store: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _userSignup = async (
  requestData: UserSignupRequestDTO,
  deps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<AuthResponseDTO> => {
  const { logger, fetcher } = deps;

  try {
    const { data } = await fetcher.post('/v1/api/user/signup', {
      ...requestData,
    });

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._userSignup',
      message: `Failed to signup user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchBMLUser = async (
  jwt: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<AuthResponseDTO> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(`/v1/api/user/bmllogin?token=${jwt}`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._fetchBMLUser',
      message: `Failed to fetch BML user settings: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _updateUserProfile = async (
  userID: string,
  profileData: UserProfileRequestDTO,
  deps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<void> => {
  const { logger, fetcher } = deps;

  try {
    const { data } = await fetcher.post(`/v1/api/clients/${userID}`, {
      ...profileData,
    });

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._updateUserProfile',
      message: `Failed to update user profile data: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _forgotPassword = async (
  email: string,
  recaptcha: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<void> => {
  const { logger, fetcher } = defaultDeps;

  try {
    return await fetcher.post(`/v1/api/user/forgotpassword`, {
      email,
      recaptcha,
    });
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._forgotPassword',
      message: `Failed when making request to forgot password: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _resetPassword = async (
  resetPasswordData: ResetPasswordRequestDTO,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<void> => {
  const { logger, fetcher } = defaultDeps;

  try {
    return await fetcher.post('/v1/api/users/current/updatepassword', {
      ...resetPasswordData,
    });
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._resetPassword',
      message: `Failed to make request when reseting password: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _checkPromoCode = async (
  code: string,
  email: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<CheckCouponResponseDTO> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(
      `/v1/api/static/stripe/coupon?coupon=${code.trim()}&email=${email}`
    );

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    // backend treats invalid code as bad request, but downstream we want to differentiate between
    // an actual malformed request/error with request and simply an invalid code
    if (aerr.response?.status === 400) {
      return aerr.response.data;
    }
    logger.error({
      caller: 'apiAdapter._checkPromoCode',
      message: `Failed when calling the check promo code: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _createStripeSession = async (
  stripeCheckoutData: StripeCheckoutRequestDTO,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<StripeSessionResponseDTO> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.post(`/v1/api/static/stripesession`, {
      ...stripeCheckoutData,
    });

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._createStripeSession',
      message: `Failed to create a new stripe session: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _getStripePrices = async (defaultDeps: {
  logger: LoggingService;
  fetcher: AxiosInstance;
}): Promise<Price[]> => {
  const { logger, fetcher } = defaultDeps;

  try {
    // The server is doing some smarts here
    // When calling the api with ?country=true in the query params the server will return only prices
    // for the country that the user is in
    // The country that is picked is a bit of a blackbox
    const { data } = await fetcher.get<StripePricingVersion2ResponseDTO>(
      '/v1/api/static/stripe/onlineprices?country=true&ver=3'
    );
    return data.price;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._getStripePrices',
      message: `Failed to fetch stripe prices from the api: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _verifyToken = async (
  token: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<void> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(`/v1/api/verify/email/${token}`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._verifyToken',
      message: `Failed to verify token for user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _createStripeUrl = async (
  returnUrl: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<string> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.post('/v1/api/static/stripe', { returnUrl });

    return data.url;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._createStripeUrl',
      message: `Failed to create the return stripe url for user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _checkUser = async (defaultDeps: {
  logger: LoggingService;
  fetcher: AxiosInstance;
}): Promise<CheckUserResponseDTO> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get('/v1/api/clients/check');

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._checkUser',
      message: `Failed to check status of user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _checkVideoSession = async (
  sessionId: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<SessionVideoCheckResponseDTO> => {
  const { logger, fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(`/v1/api/class/${sessionId}/video`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;

    logger.error({
      caller: 'apiAdapter._checkVideoSession',
      message: `Failed to check status of the session: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchDirectCoachBookings = async (
  userId: string,
  defaultDeps: {
    fetcher: AxiosInstance;
  }
): Promise<BookingForStudent[]> => {
  const { fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get<
      DirectCoachBookingResponseDTO[] | undefined
    >(`/v1/api/booking/${userId}/ClientID`);

    if (!data) {
      return [];
    }
    const bookingsForStudent = data
      .map<BookingForStudent>(directCoachResponseDTOtoDomain)
      .filter(({ sessionDC }) => isSessionValidDC(sessionDC))
      .filter(({ classBML }) => isClassValid(classBML))
      .sort((a, b) =>
        sortByDateEarliestFirst(
          a.sessionDC.dateRange.startDate,
          b.sessionDC.dateRange.startDate
        )
      );

    return bookingsForStudent;
  } catch (err) {
    const aerr = err as AxiosError;
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _cancelDirectCoachBooking = async (
  bookingId: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
) => {
  const { fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(`/v1/api/booking/${bookingId}/cancel`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchUserPrivate = async (
  userId: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<UserPrivateResponseDTO> => {
  const { fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get(`/v1/api/clients/${userId}/private`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;

    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchSessionByClass = async (
  classId: string,
  defaultDeps: {
    fetcher: AxiosInstance;
  }
): Promise<SessionByClassDC[]> => {
  const { fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get<GetDCClassesResponseDTO[] | undefined>(
      `/v1/api/ots?fields=ClassID&fieldvalues=${classId}`
    );

    if (!data) return [];

    const mapped = data
      .map(getSessionsByClassResponseDTOtoSessionByClass)
      .filter(({ session }) => isSessionValidDC(session))
      .sort((a, b) =>
        sortByDateEarliestFirst(
          a.session.dateRange.startDate,
          b.session.dateRange.startDate
        )
      );

    return mapped;
  } catch (err) {
    const aerr = err as AxiosError;

    // if no sessions on class found, BE throws 500 :/
    if (
      aerr.response?.data.message === '_Data Error error : models: Not Found_'
    ) {
      return [];
    }

    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _fetchAllAvailableSessionsBefore = async (
  before: Date,
  defaultDeps: {
    fetcher: AxiosInstance;
  }
): Promise<SessionByClassDC[]> => {
  const { fetcher } = defaultDeps;

  try {
    const { data } = await fetcher.get<GetDCClassesResponseDTO[] | undefined>(
      `/v1/api/ots?fields=StartDateTime>&fieldvalues=${before.toISOString()}`
    );

    if (!data) return [];

    const mapped = data
      .map(getSessionsByClassResponseDTOtoSessionByClass)
      .filter(({ session }) => isSessionValidDC(session));

    return mapped;
  } catch (err) {
    const aerr = err as AxiosError;

    // if no sessions on class found, BE throws 500 :/
    if (
      aerr.response?.data.message === '_Data Error error : models: Not Found_'
    ) {
      return [];
    }

    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const apiAdapter = (): ApiService => {
  const logger: LoggingService = loggingAdapter();
  const fetcher: AxiosInstance = getApiFetcher();

  return {
    login: (username: string, password: string): Promise<AuthResponseDTO> =>
      _login(username, password, { logger, fetcher }),
    fetchUser: (userId: string): Promise<UserResponseDTO> =>
      _fetchUser(userId, { logger, fetcher }),
    fetchDataStore: (): Promise<Settings> =>
      _fetchUserSettings({ logger, fetcher }),
    signupUser: (data: UserSignupRequestDTO): Promise<AuthResponseDTO> =>
      _userSignup(data, { logger, fetcher }),
    saveToDataStore: (data: Settings): Promise<Settings> =>
      _saveUserSettings(data, { logger, fetcher }),
    fetchBMLUser: (jwt: string): Promise<AuthResponseDTO> =>
      _fetchBMLUser(jwt, { logger, fetcher }),
    updateUserProfile: (
      userID: string,
      data: UserProfileRequestDTO
    ): Promise<void> => _updateUserProfile(userID, data, { logger, fetcher }),
    forgotPassword: (email: string, recaptcha: string): Promise<void> =>
      _forgotPassword(email, recaptcha, { logger, fetcher }),
    resetPassword: (data: ResetPasswordRequestDTO): Promise<void> =>
      _resetPassword(data, { logger, fetcher }),
    checkStripePromoCode: (code: string, email: string): Promise<any> =>
      _checkPromoCode(code, email, { logger, fetcher }),
    createStripeSession: (
      data: StripeCheckoutRequestDTO
    ): Promise<StripeSessionResponseDTO> =>
      _createStripeSession(data, { logger, fetcher }),
    fetchStripePrices: (): Promise<any> =>
      _getStripePrices({ logger, fetcher }),
    verifyUserToken: (token: string): Promise<void> =>
      _verifyToken(token, { logger, fetcher }),
    fetchStripeUrlForUser: (returnUrl: string): Promise<string> =>
      _createStripeUrl(returnUrl, { logger, fetcher }),
    checkUser: (): Promise<CheckUserResponseDTO> =>
      _checkUser({ logger, fetcher }),
    checkVideoSession: (
      sessionId: string
    ): Promise<SessionVideoCheckResponseDTO> =>
      _checkVideoSession(sessionId, { logger, fetcher }),
    fetchDirectCoachBooking: (userId: string): Promise<BookingForStudent[]> =>
      _fetchDirectCoachBookings(userId, { fetcher }),
    cancelDirectCoachBooking: (bookingId: string) =>
      _cancelDirectCoachBooking(bookingId, { logger, fetcher }),
    fetchUserPrivate: (userId: string): Promise<UserPrivateResponseDTO> =>
      _fetchUserPrivate(userId, { logger, fetcher }),
    fetchSessionByClass: (classId: string): Promise<SessionByClassDC[]> =>
      _fetchSessionByClass(classId, { fetcher }),
    fetchAllAvailableSessionsBefore: (
      before: Date
    ): Promise<SessionByClassDC[]> =>
      _fetchAllAvailableSessionsBefore(before, { fetcher }),
  };
};
