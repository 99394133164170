import { Recommendation } from '../domain/recommendations';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS',
}

export type State = {
  recommendations: Recommendation[] | undefined;
};

type Payload = {
  [ActionTypes.SET_RECOMMENDATIONS]: Recommendation[];
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {
  recommendations: undefined,
};

export const reducer = (state: State, action: AllActions): State => {
  switch (action.type) {
    case ActionTypes.SET_RECOMMENDATIONS:
      return { recommendations: action.payload };

    default:
      return state;
  }
};
