// Example feature left here for testing purposes
export enum FeatureToggleList {
  ExampleFeature = 'EXAMPLE_FEATURE',
  DirectCoach = 'DIRECT_COACH',
}

// Have to use this since next removes all process.env.NEXT_PUBLIC that aren't accessed at build time
// Add potential feature flags here

export const FEATURE_TOGGLE_ENVS: {
  [key in FeatureToggleList]: string | undefined;
} = {
  [FeatureToggleList.ExampleFeature]:
    process.env.NEXT_PUBLIC_FEATURE_TOGGLE_EXAMPLE,
  [FeatureToggleList.DirectCoach]:
    process.env.NEXT_PUBLIC_FEATURE_TOGGLE_DIRECT_COACH,
};
