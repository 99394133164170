import { destroyCookie, parseCookies, setCookie } from 'nookies';
import {
  computeRootDomain,
  CookieKeys,
  Environment,
  getEnvironment,
} from '../domain/websiteDomain';
import { AuthResponseDTO } from './backendDTOs';
import { loggingAdapter } from './loggingAdapter';
import { CookieService, LoggingService } from './ports';

export interface NookiesWrapper {
  parse: (...args: any[]) => any;
  set: (...args: any[]) => any;
  destroy: (...args: any[]) => any;
}

export const cookieOptionsFactory = (deps: { logger: LoggingService }) => {
  const { logger } = deps;

  logger.debug({
    caller: 'cookieOptionsFactory',
    message: 'Getting new cookie options for cookie',
  });

  const env = getEnvironment();

  return {
    path: '/',
    domain:
      env === Environment.Local || env === Environment.Preview
        ? undefined
        : computeRootDomain(),
    secure: computeRootDomain() === 'localhost' ? false : true,
    maxAge: 2592000, // 30 * 24 * 60 * 60
  };
};

export const _setAuthCookie = (
  data: AuthResponseDTO,
  defaultDeps: { cookies: NookiesWrapper; logger: LoggingService }
) => {
  const { cookies, logger } = defaultDeps;

  logger.debug({
    caller: '_setAuthCookie',
    message: 'Setting the user auth cookie',
  });

  cookies.set(
    null,
    CookieKeys.AUTH,
    JSON.stringify(data),
    cookieOptionsFactory({ logger })
  );

  cookies.set(null, 'hello', 'data', {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
    secure: true,
  });
};

export const _getAuthCookie = (defaultDeps: {
  cookies: NookiesWrapper;
  logger: LoggingService;
}) => {
  const { cookies } = defaultDeps;

  // Hack to get the cookie parsing working
  type m = {
    [key: string]: string;
  };

  const c = cookies.parse() as m;

  return c[CookieKeys.AUTH] ? JSON.parse(c[CookieKeys.AUTH]) : undefined;
};

export const _destroyAuthCookie = (defaultDeps: {
  cookies: NookiesWrapper;
  logger: LoggingService;
}): void => {
  const { cookies, logger } = defaultDeps;

  logger.debug({
    caller: '_destroyAuthCookie',
    message: 'Removing the auth cookie for the user',
  });

  cookies.destroy(null, CookieKeys.AUTH, cookieOptionsFactory({ logger }));
};

export const _getCookieNoticeCookie = (defaultDeps: {
  cookies: NookiesWrapper;
  logger: LoggingService;
}) => {
  const { cookies, logger } = defaultDeps;
  const cookieKey = CookieKeys.COOKIES_ACCEPTED;

  logger.debug({
    caller: '_getCookieNoticeCookie',
    message: 'Getting the cookie notice cookie',
  });

  // Hack to get the cookie parsing working
  type m = {
    [key: string]: string;
  };

  const c = cookies.parse() as m;

  return c[cookieKey] ? JSON.parse(c[cookieKey]) : undefined;
};

export const _setCookieNoticeCookie = (
  data: boolean,
  defaultDeps: { cookies: NookiesWrapper; logger: LoggingService }
) => {
  const { cookies, logger } = defaultDeps;

  logger.debug({
    caller: '_setCookieNoticeCookie',
    message: 'Setting the cookie notice cookie',
  });

  cookies.set(
    null,
    CookieKeys.COOKIES_ACCEPTED,
    JSON.stringify(data),
    cookieOptionsFactory({ logger })
  );
};

export const cookieAdapter = (): CookieService => {
  const logger: LoggingService = loggingAdapter();

  const cookies: NookiesWrapper = {
    parse: parseCookies,
    set: setCookie,
    destroy: destroyCookie,
  };

  return {
    setAuthCookie: (data: AuthResponseDTO) =>
      _setAuthCookie(data, { cookies, logger }),
    getAuthCookie: () => _getAuthCookie({ cookies, logger }),
    destroyAuthCookie: () => _destroyAuthCookie({ cookies, logger }),
    getCookieNoticeAcceptedCookie: () =>
      _getCookieNoticeCookie({ cookies, logger }),
    setCookieNoticeAcceptedCookie: (data: boolean) =>
      _setCookieNoticeCookie(data, { cookies, logger }),
  };
};
