import LocalStorageHelper from '../adapters/helpers/localStorageHelper';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_FILTERS = 'SET_FILTERS',
  RESET_FILTERS = 'RESET_FILTERS',
}

export type State = {
  [key: string]: any[];
};

type Payload = {
  [ActionTypes.SET_FILTERS]: State;
  [ActionTypes.RESET_FILTERS]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {};

export const reducer = (state: State, action: AllActions): State => {
  const storageHelper = LocalStorageHelper.getInstance();

  switch (action.type) {
    case ActionTypes.SET_FILTERS: {
      const updatedState = { ...state, ...action.payload };

      // removes all entries where the value is empty
      // could move this to a helper function
      Object.keys(updatedState).forEach(key => {
        const value = updatedState[key];
        if (
          value === null ||
          value === undefined ||
          (Array.isArray(value) && value.length === 0)
        )
          delete updatedState[key];
      });

      storageHelper.setFilterData(updatedState);

      return updatedState;
    }

    case ActionTypes.RESET_FILTERS:
      storageHelper.clearFilters();

      return {};

    default:
      return state;
  }
};
