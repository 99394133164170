import { DateRange } from '../../global';

export const dateRangeMapper = (
  startDate: string,
  endDate: string
): DateRange => {
  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
};
