import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { analyticsService } from '../../../application/analytics';

interface AnalyticsEffectsProps {}

const AnalyticsEffects: React.FC<AnalyticsEffectsProps> = ({ children }) => {
  const router = useRouter();

  // pageview events in Google Analytics
  useEffect(() => {
    const analytics = analyticsService();
    const handleRouteChange = (url: string) => {
      analytics.recordPageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <>{children}</>;
};

export default AnalyticsEffects;
