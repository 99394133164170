import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import LocalStorageHelper from '../../../adapters/helpers/localStorageHelper';

interface AppEffectsProps {}

const AppEffects: React.FC<AppEffectsProps> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const storageHelper = LocalStorageHelper.getInstance();
    // Check if there is a query parameter for a coupon when app mounts
    // If so, save to local storage for use before checkout
    const code = router?.query?.coupon || router?.query?.tid;

    if (code) {
      storageHelper.setCouponData(code);
    }
  }, [router.query]);

  return <>{children}</>;
};

export default AppEffects;
