import axios from 'axios';
import dayjs from 'dayjs';
import getUserLocale from 'get-user-locale';
import { CustomError } from 'ts-custom-error';
import { apiAdapter, ApiAdapterError } from '../adapters/apiAdapter';
import {
  AuthResponseDTO,
  ResetPasswordRequestDTO,
  UserDTO,
  UserProfileRequestDTO,
  UserSignupRequestDTO,
} from '../adapters/backendDTOs';
import { cookieAdapter } from '../adapters/cookieAdapter';
import { analyticsAdapter } from '../adapters/gtagAdapter';
import { localStorageAdapter } from '../adapters/localStorageAdapter';
import { loggingAdapter } from '../adapters/loggingAdapter';
import {
  userCheckResponseDTOtoUserChecks,
  userDTOtoUser,
} from '../adapters/mappers/userMapper';
import {
  ApiService,
  CookieService,
  GoogleAnalyticsService,
  LocalStorageService,
  LoggingService,
  RecommenderAdapter,
} from '../adapters/ports';
import { recommenderAdapter } from '../adapters/recommenderAdapter';
import { Recommendation } from '../domain/recommendations';
import {
  getUnauthenticatedUser,
  Settings,
  updateUserChecks,
  updateUserLoggedIn,
  updateUserSettings,
  User,
  UserChecks,
} from '../domain/user';
import { DataStoreKeys } from '../domain/websiteDomain';
import { ClassFragment } from '../typescript/generated/codegen';
export const DATA_STORE_KEY = 'settings';

export class UserApplicationError extends CustomError {
  public constructor(public type: string, message?: string) {
    super(message);
  }
}

export const _userApplicationErrorFactory = (
  options: { type: string; caller: string; message: string; err: Error },
  defaultDeps: { logging: LoggingService }
): UserApplicationError => {
  const { logging } = defaultDeps;
  const { caller, message, err, type } = options;

  const error = new UserApplicationError(type, message);
  logging.error({
    caller: caller,
    message: `${message}: ${err}`,
  });
  logging.error({
    caller: `application.${caller}`,
    message: `Returning application layer error: ${error}`,
  });

  return error;
};

export async function _authenticate(
  username: string,
  password: string,
  defaultDeps: { api: ApiService }
): Promise<AuthResponseDTO> {
  const { api } = defaultDeps;

  return api.login(username, password);
}

export async function _fetchUser(
  userID: string,
  defaultDeps: {
    api: ApiService;
  }
): Promise<UserDTO> {
  const { api } = defaultDeps;

  const { client } = await api.fetchUser(userID);

  return client;
}

export async function _fetchUserSettings(defaultDeps: {
  api: ApiService;
}): Promise<Settings> {
  const { api } = defaultDeps;

  return api.fetchDataStore();
}

export async function _fetchUserWithSettings(defaultDeps: {
  cookies: CookieService;
  api: ApiService;
  localStore: LocalStorageService;
  logging: LoggingService;
}): Promise<User> {
  const { api, cookies, localStore, logging } = defaultDeps;

  try {
    const authCookieData = cookies.getAuthCookie();

    if (!authCookieData) {
      logging.debug({
        caller: `_fetchUserWithSettings`,
        message: `Failed to find auth cookie. Returning unauthenticated user`,
      });
      return getUnauthenticatedUser(getUserLocale());
    }

    const uid = authCookieData.userID;

    const user: UserDTO = await _fetchUser(uid, { api });
    const settings: Settings = await _fetchUserSettings({ api });
    const userChecks = await _checkUser({ logging, api });

    let u = userDTOtoUser(user);

    u = updateUserSettings(u, settings);
    u = updateUserChecks(u, userChecks);
    u = updateUserLoggedIn(u, true);

    localStore.setUserData(u);

    return u;
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'FetchUserWithSettingsError',
        message: 'Failed to fetch user and settings',
        caller: 'user._fetchUserWithSetting',
        err: err as Error,
      },
      { logging }
    );
  }
}

export async function _loginUser(
  username: string,
  password: string,
  defaultDeps: {
    cookies: CookieService;
    api: ApiService;
    localStore: LocalStorageService;
    analytics: GoogleAnalyticsService;
    logging: LoggingService;
  }
): Promise<User> {
  const { cookies, api, localStore, analytics, logging } = defaultDeps;

  try {
    const authResponse: AuthResponseDTO = await _authenticate(
      username,
      password,
      {
        api,
      }
    );
    cookies.setAuthCookie(authResponse);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'AuthenticationError',
        message: 'Failed to authenticate user',
        caller: 'user._logingUser',
        err: err as Error,
      },
      { logging }
    );
  }

  try {
    logging.debug({
      caller: '_loginUser',
      message: 'Starting fetch of user details and settings',
    });
    const u = await _fetchUserWithSettings({
      api,
      cookies,
      localStore,
      logging,
    });

    analytics.login(u);

    logging.debug({
      caller: '_loginUser',
      message: 'Successfully fetched user details and settings',
    });
    return u;
  } catch (err) {
    cookies.destroyAuthCookie();

    throw _userApplicationErrorFactory(
      {
        type: 'FetchUserDetailsError',
        message: 'Failed to fetch user details',
        caller: 'user._loginUser',
        err: err as Error,
      },
      { logging }
    );
  }
}

export function _logoutUser(deps: {
  cookies: CookieService;
  localStore: LocalStorageService;
  logging: LoggingService;
}): User {
  const { cookies, localStore, logging } = deps;

  try {
    const unauthedUser = getUnauthenticatedUser(getUserLocale());

    cookies.destroyAuthCookie();
    localStore.destroyUserData();
    localStore.setUserData(unauthedUser);

    return unauthedUser;
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'LogoutError',
        message: 'Failed to logout user',
        caller: 'user._logoutUser',
        err: err as Error,
      },
      { logging }
    );
  }
}

export async function _signupUser(
  signupData: UserSignupRequestDTO,
  defaultDeps: {
    cookies: CookieService;
    api: ApiService;
    localStore: LocalStorageService;
    analytics: GoogleAnalyticsService;
    logging: LoggingService;
  }
): Promise<User> {
  const { cookies, api, localStore, analytics, logging } = defaultDeps;

  const { email, confirm_password } = signupData;

  try {
    const authResult = await api.signupUser(signupData);

    cookies.setAuthCookie(authResult);
  } catch (err) {
    const aerr = err as ApiAdapterError;
    let errorMessage = 'There was an issue creating the user. Please try again';
    if (aerr?.data) {
      switch ((aerr?.data as any).status || 500) {
        case '40105':
          errorMessage = 'Email address already in use';
          break;
        default:
          break;
      }
    }

    throw _userApplicationErrorFactory(
      {
        type: 'SignupError',
        message: errorMessage,
        caller: 'user._signupUser',
        err: err as Error,
      },
      { logging }
    );
  }

  try {
    const u = await _loginUser(email, confirm_password, {
      cookies,
      api,
      localStore,
      analytics,
      logging,
    });

    analytics.signup(u);

    return u;
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'LoginError',
        message:
          'An issue occured, however the user was created. Please go to the login page',
        caller: 'user._signupUser',
        err: err as Error,
      },
      { logging }
    );
  }
}

export const _saveUserFavVideo = async (
  session: ClassFragment,
  defaultDeps: { logging: LoggingService; api: ApiService }
) => {
  const { logging, api } = defaultDeps;

  try {
    const existingData: Settings = await api.fetchDataStore();

    // get favourited array from settings
    const currentFavourited = existingData[DataStoreKeys.FAVOURITED] || [];
    let newFavourited;

    // If the current session already belongs to favourites, the user is trying to unfavourite so remove from the array
    // otherwise add it to the start of the array
    if (currentFavourited?.some(fav => fav.id === session.id)) {
      newFavourited = currentFavourited.filter(fav => fav.id !== session.id);
    } else {
      newFavourited = [
        {
          id: session.id || 'id',
          beid: session.beid || 'id',
        },
        ...currentFavourited,
      ];
    }

    const updatedDataStore: Settings = {
      ...existingData,
      [DataStoreKeys.FAVOURITED]: newFavourited,
    };

    await api.saveToDataStore(updatedDataStore);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'SaveFavouritesError',
        message: 'There was an issue when saving a new favourite session',
        caller: 'user._saveUserVideo',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _saveUserSessionView = async (
  session: ClassFragment,
  defaultDeps: { logging: LoggingService; api: ApiService }
): Promise<void> => {
  const { logging, api } = defaultDeps;

  try {
    const existingData: Settings = await api.fetchDataStore();

    // get viewed array from settings and then filter any other instances where the same video was viewed
    // so that you don't have the same video appearing multiple times
    const viewed = (existingData[DataStoreKeys.VIEWED] || []).filter(
      item => item.id !== session.id
    );

    // add the currently viewed item to beginning of the array
    viewed.unshift({
      id: session.id || '',
      beid: session.beid || '',
      viewedAt: dayjs().toISOString(),
    });

    const updatedDataStore = {
      ...existingData,
      [DataStoreKeys.VIEWED]: viewed,
    };

    await api.saveToDataStore(updatedDataStore);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'SaveUserSessionViewError',
        message: `There was an error when saving a view for sessions: ${session.beid}`,
        caller: 'user._saveUserSessionView',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _saveUserOnboardingData = async (
  data: any,
  defaultDeps: { logging: LoggingService; api: ApiService }
): Promise<void> => {
  const { logging, api } = defaultDeps;

  try {
    const existingData: Settings = await api.fetchDataStore();

    const updatedDataStore = {
      ...existingData,
      [DataStoreKeys.ONBOARDING_DATA]: data,
    };

    await api.saveToDataStore(updatedDataStore);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'SaveUserOnboardingDataError',
        message: `There was an error when saving onboading data for the user`,
        caller: 'user._saveUserOnboardingData',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _getUserAuthCookie = (defaultDeps: {
  cookies: CookieService;
  logging: LoggingService;
}): AuthResponseDTO | undefined => {
  const { cookies, logging } = defaultDeps;

  try {
    return cookies.getAuthCookie();
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'GetUserAuthCookieError',
        message: `Failed to get the user auth cookie`,
        caller: 'user._getUserAuthCookie',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _authenticateBMLUser = async (
  jwtToken: string,
  defaultDeps: {
    logging: LoggingService;
    api: ApiService;
    cookies: CookieService;
  }
) => {
  const { logging, api, cookies } = defaultDeps;

  try {
    const user = await api.fetchBMLUser(jwtToken);

    cookies.setAuthCookie(user);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'AuthenticateBMLUserError',
        message: `Failed to authenticate a BML user`,
        caller: 'user._authenticateBMLUser',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _updateUserProfile = async (
  userID: string | undefined,
  data: UserProfileRequestDTO,
  defaultDeps: {
    logging: LoggingService;
    cookies: CookieService;
    api: ApiService;
    localStore: LocalStorageService;
  }
): Promise<User> => {
  const { logging, api, cookies, localStore } = defaultDeps;

  if (!userID) {
    throw _userApplicationErrorFactory(
      {
        type: 'UserProfileUpdateError',
        message: `There was an issue saving this profile data`,
        caller: 'user._updateUserProfile',
        err: new Error('User id does not exist'),
      },
      { logging }
    );
  }

  try {
    await api.updateUserProfile(userID, data);
  } catch (err) {
    const aerr = err as ApiAdapterError;

    // Hate this
    if (
      aerr.data?.message ===
      "_Database Error : Can't save client as email exists with another user_"
    ) {
      throw _userApplicationErrorFactory(
        {
          type: 'UserProfileUpdateError',
          message: `Email already exists, please try a different email.`,
          caller: 'user._updateUserProfile',
          err: aerr,
        },
        { logging }
      );
    }

    throw _userApplicationErrorFactory(
      {
        type: 'UserProfileUpdateError',
        message: `There was an issue saving this profile data`,
        caller: 'user._updateUserProfile',
        err: err as Error,
      },
      { logging }
    );
  }

  try {
    const user = await _fetchUserWithSettings({
      cookies,
      api,
      localStore,
      logging,
    });
    return user;
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'FetchUserFailedError',
        message: `Failed to fetch user details after updating profile`,
        caller: 'user._updateUserProfile',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _getUserRecommendations = async (defaultDeps: {
  logging: LoggingService;
  recommender: RecommenderAdapter;
  localStore: LocalStorageService;
}): Promise<Recommendation[]> => {
  const { logging, recommender, localStore } = defaultDeps;

  const userData = localStore.getUserData();

  if (!userData?.id) {
    // log error that there is no user and return
    return [];
  }

  try {
    return await recommender.fetchUserRecommendations(userData.id);
  } catch (err) {
    logging.error({
      caller: 'user._getUserRecommendations',
      message: `Failed to fetch recommendataions for the user: ${JSON.stringify(
        err
      )}`,
    });
    return [];
  }
};

export const _userForgotPassword = async (
  email: string,
  recaptcha: string,
  defaultDeps: { logging: LoggingService; api: ApiService }
) => {
  const { logging, api } = defaultDeps;

  try {
    await api.forgotPassword(email, recaptcha);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'ForgotPasswordError',
        message: `There was an error when sending forgot password request`,
        caller: 'user._userForgotPassword',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _userResetPassword = async (
  data: ResetPasswordRequestDTO,
  defaultDeps: { logging: LoggingService; api: ApiService }
) => {
  const { logging, api } = defaultDeps;

  try {
    await api.resetPassword(data);
  } catch (err) {
    throw _userApplicationErrorFactory(
      {
        type: 'ForgotPasswordError',
        message: `There was an error when sending forgot password request`,
        caller: 'user._userForgotPassword',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _verifyUserWithToken = async (
  token: string,
  user: User,
  defaultDeps: {
    logging: LoggingService;
    api: ApiService;
    analytics: GoogleAnalyticsService;
  }
) => {
  const { logging, api, analytics } = defaultDeps;

  try {
    await api.verifyUserToken(token);
    analytics.verifyEmailSuccess(user);
  } catch (err) {
    analytics.verifyEmailFail(user);
    throw _userApplicationErrorFactory(
      {
        type: 'VerifyUserTokenError',
        message: 'There was an error when verifying the user token',
        caller: 'user._verifyUserWithToken',
        err: err as Error,
      },
      { logging }
    );
  }
};

export const _checkUser = async (defaultDeps: {
  logging: LoggingService;
  api: ApiService;
}): Promise<UserChecks> => {
  const { logging, api } = defaultDeps;

  try {
    logging.debug({
      caller: '_checkUser',
      message: 'checking user access and notifications',
    });
    const res = await api.checkUser();
    return userCheckResponseDTOtoUserChecks(res);
  } catch (err) {
    const errorCast = err as Error;

    throw _userApplicationErrorFactory(
      {
        type: 'CheckUserError',
        message: 'There was an error when checking status of the user',
        caller: 'user._checkUser',
        err: errorCast,
      },
      { logging }
    );
  }
};

export const _refreshUserCheck = async (defaultDeps: {
  logging: LoggingService;
  api: ApiService;
  localStore: LocalStorageService;
}): Promise<User> => {
  const { logging, api, localStore } = defaultDeps;
  const caller = '_refreshUserCheck';

  logging.debug({ caller, message: 'Refreshing user check results' });

  let u = localStore.getUserData();

  if (!u) {
    throw _userApplicationErrorFactory(
      {
        type: 'CheckUserError',
        message: 'Trying to refresh user checks but there is no active user',
        caller: 'user._refreshUserCheck',
        err: new Error('No user in local storage'),
      },
      { logging }
    );
  }

  try {
    const checkResponse: UserChecks = await _checkUser({
      logging,
      api,
    });

    u = updateUserChecks(u, checkResponse);

    return u;
  } catch (err) {
    const errorCast = err as Error;
    throw _userApplicationErrorFactory(
      {
        type: 'RefreshUserChecksError',
        message: 'Failed to refresh user checks',
        caller: 'user._refreshUserCheck',
        err: errorCast,
      },
      { logging }
    );
  }
};

export const refreshAccessToken = async (): Promise<void> => {
  const cookies = cookieAdapter();
  const logger = loggingAdapter();
  const analytics = analyticsAdapter();
  const storage = localStorageAdapter();

  const authCookie = cookies.getAuthCookie();

  if (!authCookie) {
    window.location.href = `/user/login?target=${window.location.pathname}`;
  }

  const endpoint = `${process.env.NEXT_PUBLIC_BE_API_ROOT_DOMAIN}/v1/api/user/refresh?refresh=${authCookie?.refresh}`;

  try {
    const refresh = await axios.get(endpoint);

    cookies.setAuthCookie(refresh.data);

    analytics.recordRefreshTokenSuccess();

    logger.debug({
      caller: 'getApiFetcher.wrapAxiosWithAuthRefresh',
      message: 'Token refresh complete',
    });

    return await Promise.resolve();
  } catch (err) {
    const aerr = err as Error;

    analytics.recordRefreshTokenFailure(authCookie?.refresh);

    cookies.destroyAuthCookie();
    storage.destroyUserData();

    window.location.href = `/user/login?target=${window.location.pathname}&error=token_refresh_fail`;

    logger.error({
      caller: 'getApiFetcher.wrapAxiosWithAuthRefresh',
      message: `Failed to refresh auth token: ${aerr.message}`,
    });
    return await Promise.resolve();
  }
};

export function userService() {
  const api: ApiService = apiAdapter();
  const cookies: CookieService = cookieAdapter();
  const localStore: LocalStorageService = localStorageAdapter();
  const analytics: GoogleAnalyticsService = analyticsAdapter();
  const logging: LoggingService = loggingAdapter();
  const recommender = recommenderAdapter();

  return {
    loginUser: (username: string, password: string): Promise<User> =>
      _loginUser(username, password, {
        cookies,
        api,
        localStore,
        analytics,
        logging,
      }),
    logoutUser: (): User => _logoutUser({ cookies, localStore, logging }),
    signupUser: (data: UserSignupRequestDTO): Promise<User> =>
      _signupUser(data, {
        cookies,
        api,
        localStore,
        analytics,
        logging,
      }),
    saveUserFavouriteVideo: (session: ClassFragment): Promise<void> =>
      _saveUserFavVideo(session, { logging, api }),
    saveSessionView: (session: ClassFragment): Promise<void> =>
      _saveUserSessionView(session, { logging, api }),
    fetchUserWithSettingsOrUnauthedUser: (): Promise<User> =>
      _fetchUserWithSettings({ api, cookies, localStore, logging }),
    getUserAuthCookie: (): AuthResponseDTO | undefined =>
      _getUserAuthCookie({ cookies, logging }),
    authenticateBMLUser: (jwtToken: string): Promise<void> =>
      _authenticateBMLUser(jwtToken, { logging, api, cookies }),
    saveUserOnboardingData: (data: any): Promise<void> =>
      _saveUserOnboardingData(data, { logging, api }),
    updateUserProfile: (
      userID: string | undefined,
      data: UserProfileRequestDTO
    ): Promise<User> =>
      _updateUserProfile(userID, data, {
        logging,
        api,
        cookies,
        localStore,
      }),
    fetchUserRecommendations: (): Promise<Recommendation[]> =>
      _getUserRecommendations({ logging, recommender, localStore }),
    userForgotPassword: (email: string, recaptcha: string): Promise<void> =>
      _userForgotPassword(email, recaptcha, { logging, api }),
    resetPassword: (data: ResetPasswordRequestDTO): Promise<void> =>
      _userResetPassword(data, { logging, api }),
    verifyUser: (token: string, user: User): Promise<void> =>
      _verifyUserWithToken(token, user, { logging, api, analytics }),
    refreshUserCheck: (): Promise<User> =>
      _refreshUserCheck({ logging, api, localStore }),
  };
}
