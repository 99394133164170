import { DCSessionsByClassTag } from '../domain/directCoach';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_DIRECT_COACH_NEXT_SESSION = 'SET_DIRECT_COACH_NEXT_SESSION',
}

export type State = {
  directCoachNextSessionsByClassTag: DCSessionsByClassTag | undefined;
};

type Payload = {
  [ActionTypes.SET_DIRECT_COACH_NEXT_SESSION]: DCSessionsByClassTag | undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {
  directCoachNextSessionsByClassTag: undefined,
};

export const reducer = (state: State, action: AllActions): State => {
  switch (action.type) {
    case ActionTypes.SET_DIRECT_COACH_NEXT_SESSION:
      return { ...state, directCoachNextSessionsByClassTag: action.payload };

    default:
      return state;
  }
};
