import { DefaultSeo, LogoJsonLd, SocialProfileJsonLd } from 'next-seo';
import React from 'react';
import { config } from '../../../domain/siteConfig';

interface DefaultSEOProps {}

const DefaultSEO: React.FC<DefaultSEOProps> = () => {
  const logo = `${process.env.NEXT_PUBLIC_APP_ROOT_DOMAIN}/logo.jpg`;

  return (
    <>
      <DefaultSeo
        title={config.title}
        description={config.description}
        openGraph={{
          type: 'website',
          locale: config.locale,
          url: config.url,
          site_name: config.title,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_APP_ROOT_DOMAIN}/og-image.jpg`,
              width: 1200,
              height: 630,
              alt: config.title,
            },
          ],
        }}
      />

      <LogoJsonLd logo={logo} url={config.url || ''} />
      <SocialProfileJsonLd
        type="Organization"
        name={config.title}
        url={config.url || ''}
        sameAs={[config.facebook.url, config.instagram.url]}
      />
      {/* 
        TODO:
          CorporateContactJsonLd
          FAQPageJsonLd
          QAPageJsonld
          CarouselJsonLd
      */}
    </>
  );
};

export default DefaultSEO;
