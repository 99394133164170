import axios from 'axios';
import { refreshAccessToken } from '../../application/user';
import { generateAuthToken } from '../../domain/websiteDomain';
import { cookieAdapter } from '../cookieAdapter';

let isRefreshing = false;
const refreshSubscribers: any[] = [];

const getApiFetcher = () => {
  const subscribeTokenRefresh = (cb: any) => {
    refreshSubscribers.push(cb);
  };

  const onRefreshed = () => {
    refreshSubscribers.map(cb => cb());
  };

  const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BE_API_ROOT_DOMAIN,
  });

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (!axios.isAxiosError(error)) return Promise.reject(error);

      if (error.response?.status !== 401) return Promise.reject(error);

      // don't refresh on login
      if (error.request.responseURL.includes('/v1/api/user/login'))
        return Promise.reject(error);

      // don't refresh on auth refresh
      if (error.request.responseURL.includes('/v1/api/auth/refresh'))
        return Promise.reject(error);

      const { config } = error;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshAccessToken().then(() => {
          isRefreshing = false;
          onRefreshed();
        });
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(() => {
          resolve(axiosInstance(config));
        });
      });
      return retryOrigReq;
    }
  );

  // TODO: make work on server side
  axiosInstance.interceptors.request.use(request => {
    const cookies = cookieAdapter();
    const accessToken = cookies.getAuthCookie()?.access;

    if (!accessToken || !request.headers) {
      return request;
    }

    request.headers.Authorization = generateAuthToken(accessToken);

    return request;
  });

  return axiosInstance;
};

export default getApiFetcher;
