import dayjs from 'dayjs';
import { isInFuture, isInPast } from '../libs/time';
import { DateRange } from './../global.d';
import { Class } from './class';
import { Teacher } from './teacher';

export type SessionDC = {
  id: string;
  dateRange: DateRange;
  numberBooked: number;
  numberLimit: number;
  costInDollars: number;
  classLengthInSeconds: number;
  isCancelled: boolean;
};

export enum SessionDCStatus {
  Available,
  Full,
}

export type SessionDCStatusMeta = {
  text: string;
  type: SessionDCStatus;
};

export const getAvailability = ({
  numberBooked,
  numberLimit,
}: Pick<SessionDC, 'numberBooked' | 'numberLimit'>): SessionDCStatus =>
  numberBooked < numberLimit ? SessionDCStatus.Available : SessionDCStatus.Full;

export const getSessionAvailabilityMeta = (
  session: Pick<SessionDC, 'numberBooked' | 'numberLimit'>
) => {
  const availability = getAvailability(session);

  const options = {
    [SessionDCStatus.Available]: {
      text: `${session.numberLimit - session.numberBooked} spots available`,
      status: SessionDCStatus.Available,
    },
    [SessionDCStatus.Full]: {
      text: `Class full`,
      status: SessionDCStatus.Full,
    },
  };
  return options[availability];
};

export const getSessionAttendance = ({
  numberBooked,
  numberLimit,
}: Pick<SessionDC, 'numberBooked' | 'numberLimit'>): string =>
  `${numberBooked}/${numberLimit}`;

// Backend API can give bung data, some very very basic validation
export const isSessionValidDC = ({
  id,
  dateRange,
}: Pick<SessionDC, 'id' | 'dateRange'>) =>
  !!id && !!dateRange.startDate && !!dateRange.endDate;

export type BookingDC = {
  id: string;
};

export const getDirectCoachLink = ({ id }: Pick<SessionDC, 'id'>) =>
  `bodymindlifeappname://booking/${id}`;

// UI Aggregates
export interface BookingsForStudentSectionsDC {
  heading: string;
  bookings: BookingForStudent[];
  canBeCancelledOrAccessed: boolean;
}

export const getDirectCoachSection = (
  bookingsForStudentDC: BookingForStudent[],
  currentDate: Date
): BookingsForStudentSectionsDC[] => {
  const studentBookingSections: BookingsForStudentSectionsDC[] = [
    {
      heading: 'upcoming',
      bookings: bookingsForStudentDC.filter(({ sessionDC }) =>
        isInFuture(sessionDC.dateRange.startDate, currentDate)
      ),
      canBeCancelledOrAccessed: true,
    },
    {
      heading: 'completed',
      bookings: bookingsForStudentDC.filter(({ sessionDC }) =>
        isInPast(sessionDC.dateRange.startDate, currentDate)
      ),
      canBeCancelledOrAccessed: false,
    },
  ];

  return studentBookingSections;
};

export type SessionByClassDC = {
  session: Pick<
    SessionDC,
    | 'costInDollars'
    | 'dateRange'
    | 'id'
    | 'numberBooked'
    | 'numberLimit'
    | 'isCancelled'
  >;
  hasBooked: boolean;
  teacher: Pick<Teacher, 'displayName'>;
  class: Pick<Class, 'id'>;
};

export type BookingForStudent = {
  bookingDC: BookingDC;
  sessionDC: Pick<
    SessionDC,
    'classLengthInSeconds' | 'dateRange' | 'id' | 'isCancelled'
  >;
  classBML: Pick<Class, 'classification' | 'imgURL' | 'className' | 'slug'>;
  teacher: Pick<Teacher, 'displayName'>;
};

export type DCSessionsByClassTag = {
  [key: string]: Pick<SessionDC, 'dateRange'>;
};

export const reduceSessionsToClassTagObjectForLookup = (
  input: SessionByClassDC[]
): DCSessionsByClassTag => {
  return input.reduce<DCSessionsByClassTag>((acc, dc) => {
    const duplicateClass = acc[dc.class.id];

    if (
      duplicateClass &&
      isInFuture(
        dc.session.dateRange.startDate,
        duplicateClass.dateRange.startDate
      )
    ) {
      return acc;
    }

    return {
      ...acc,
      [dc.class.id]: {
        dateRange: dc.session.dateRange,
      },
    };
  }, {});
};

export const onlyShowSessionTagsBefore = (currentDate: Date): Date => {
  return dayjs(currentDate).add(1, 'week').toDate();
};
