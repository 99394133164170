import dayjs from 'dayjs';
import { DateRange } from '../global';

export const convertSecondsToDurationLabel = (seconds: number) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
};

export function getNiceDate(startDate: Date, currentDate: Date) {
  const startDateDayjs = dayjs(startDate);
  return startDateDayjs.isSame(dayjs(currentDate), 'day')
    ? 'Today'
    : startDateDayjs.format('ddd D MMM');
}

export function getNiceTimeRange({ startDate, endDate }: DateRange) {
  const startDateDayjs = dayjs(startDate);
  return `${startDateDayjs.format('h:mm a')} - ${dayjs(endDate).format(
    'h:mm a'
  )}`;
}

export const niceDateAndTime = (date: Date) =>
  dayjs(date).format('dddd - h:mm a');

export const getDateTimeString = (dateRange: DateRange, currentDate: Date) => {
  const niceTimeRange = getNiceTimeRange(dateRange);
  const niceDate = getNiceDate(dateRange.startDate, currentDate);

  return `${niceDate}, ${niceTimeRange}`;
};

export const secondsToMinutesRounded = (seconds: number): number =>
  Math.round(seconds / 60);

export const getDurationReadable = (seconds: number) => {
  const roundedMinutes = secondsToMinutesRounded(seconds);

  if (roundedMinutes <= 60) {
    return `${roundedMinutes} mins`;
  }

  const hours = Math.floor(roundedMinutes / 60);

  if (roundedMinutes % 60 === 0) {
    return `${hours} hrs`;
  }

  const remainderMinutes = roundedMinutes % 60;

  if (hours === 1) {
    return `${hours} hr, ${remainderMinutes} mins`;
  }

  return `${hours} hrs, ${remainderMinutes} mins`;
};

export const isInFuture = (date: Date, currentDate: Date): boolean =>
  dayjs(date) >= dayjs(currentDate);
export const isInPast = (date: Date, currentDate: Date): boolean =>
  dayjs(date) < dayjs(currentDate);

export const sortByDateEarliestFirst = (a: Date, b: Date) => +a - +b;
