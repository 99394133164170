import React, { createContext, Dispatch, useReducer } from 'react';
import * as Carousels from './carousels';
import * as DirectCoach from './directCoach';
import * as Filters from './filters';
import * as Modals from './modals';
import * as Recommendations from './recommendations';
import * as Settings from './settings';
import * as Video from './video';

// function to create types for actions
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

// type for all actions
export type AllActions =
  | Settings.Actions
  | Filters.Actions
  | Carousels.Actions
  | Modals.Actions
  | Video.Actions
  | DirectCoach.Actions
  | Recommendations.Actions;

// enum of all action types
export const Types = {
  ...Settings.ActionTypes,
  ...Filters.ActionTypes,
  ...Carousels.ActionTypes,
  ...Modals.ActionTypes,
  ...Video.ActionTypes,
  ...DirectCoach.ActionTypes,
  ...Recommendations.ActionTypes,
};

// type of context state
export type State = {
  settings: Settings.State;
  filters: Filters.State;
  carousels: Carousels.State;
  modals: Modals.State;
  video: Video.State;
  directCoach: DirectCoach.State;
  recommendations: Recommendations.State;
};

// context initial state
const initialState: State = {
  settings: Settings.initialState,
  filters: Filters.initialState,
  carousels: Carousels.initialState,
  modals: Modals.initialState,
  video: Video.initialState,
  directCoach: DirectCoach.initialState,
  recommendations: Recommendations.initialState,
};

// create AppContext
const AppContext = createContext<{
  state: State;
  dispatch: Dispatch<AllActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

// combine reducers
const mainReducer = (
  {
    settings,
    filters,
    carousels,
    modals,
    video,
    recommendations,
    directCoach,
  }: State,
  action: AllActions
) => ({
  settings: Settings.reducer(settings, action),
  filters: Filters.reducer(filters, action),
  carousels: Carousels.reducer(carousels, action),
  modals: Modals.reducer(modals, action),
  video: Video.reducer(video, action),
  recommendations: Recommendations.reducer(recommendations, action),
  directCoach: DirectCoach.reducer(directCoach, action),
});

// create AppProvider
const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
