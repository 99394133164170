import { ApolloProvider } from '@apollo/client';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { sendWebVitals } from '../adapters/gtagAdapter';
import graphqlClient from '../apollo/client';
import Effects from '../components/app/effects/effects';
import { Scripts } from '../components/app/scripts';
import Toast from '../components/app/toast';
import DefaultSEO from '../components/layout/head/defaultSEO';
import { AppProvider } from '../context';
import '../styles/index.css';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  sendWebVitals(metric);
}

interface Props extends AppProps {
  err: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App({ Component, pageProps, err }: Props) {
  return (
    <>
      <Scripts />
      <NextNProgress
        color="#1E615A"
        startPosition={0.3}
        stopDelayMs={200}
        height={6}
        options={{ showSpinner: false }}
      />
      <ApolloProvider client={graphqlClient}>
        <AppProvider>
          <Effects>
            <DefaultSEO />
            <Toast />
            <Component {...pageProps} err={err} />
          </Effects>
        </AppProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
