import { AxiosError, AxiosInstance } from 'axios';
import { CustomError } from 'ts-custom-error';
import { Recommendation } from '../domain/recommendations';
import getRecommenderFetcher from './fetchers/recommenderFetcher';
import { loggingAdapter } from './loggingAdapter';
import { LoggingService, RecommenderAdapter } from './ports';

export class ApiAdapterError extends CustomError {
  public constructor(
    public statusCode: number,
    message?: string,
    public data?: any
  ) {
    super(message);
  }
}

export const _fetchUserRecommendations = async (
  userID: string,
  defaultDeps: {
    logger: LoggingService;
    fetcher: AxiosInstance;
  }
): Promise<Recommendation[]> => {
  const { logger, fetcher } = defaultDeps;

  const baseURL = process.env.NEXT_PUBLIC_RECOMMENDER_ROOT_DOMAIN || '';

  if (!baseURL) {
    logger.info({
      caller: '_fetchUserRecommendation',
      message: 'no base url has been supplied',
    });
    return [];
  }

  try {
    const { data } = await fetcher.get(`/v1/api/recommend/classes/${userID}`);

    return data;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      caller: 'apiAdapter._fetchUserRecommendations',
      message: `Failed to fetch class recommendations for user: ${aerr.message}`,
    });
    throw new ApiAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const recommenderAdapter = (): RecommenderAdapter => {
  const logger: LoggingService = loggingAdapter();
  const fetcher: AxiosInstance = getRecommenderFetcher();

  return {
    fetchUserRecommendations: (userID: string): Promise<Recommendation[]> =>
      _fetchUserRecommendations(userID, {
        logger,
        fetcher,
      }),
  };
};
