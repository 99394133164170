import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { apiAdapter } from '../../../adapters/apiAdapter';
import { loggingAdapter } from '../../../adapters/loggingAdapter';
import { directCoachService } from '../../../application/featureToggle';
import { AppContext } from '../../../context';
import { ActionTypes } from '../../../context/directCoach';
import {
  onlyShowSessionTagsBefore,
  reduceSessionsToClassTagObjectForLookup,
} from '../../../domain/directCoach';
import { FeatureToggleList } from '../../../domain/featureToggleEnv';
import { pageContainsClasses } from '../../../domain/websiteDomain';
import useUserWithRedirects from '../../../hooks/useUser';

interface DirectCoachEffectsProps {}

const DirectCoachEffects: React.FC<DirectCoachEffectsProps> = ({
  children,
}) => {
  const { dispatch, state } = useContext(AppContext);
  const user = useUserWithRedirects();
  const router = useRouter();

  const featureToggle = directCoachService();

  useEffect(() => {
    if (!featureToggle.shouldShowFeature(FeatureToggleList.DirectCoach)) return;

    // Only load when user hits a page that they need it on, although probably could turn to fully global, might speed up things
    if (!pageContainsClasses(router.pathname)) return;

    // Api call is protected, though this should probably change
    if (!user.isLoggedIn) return;

    // If already in state, don't reload
    if (state.directCoach.directCoachNextSessionsByClassTag) return;

    const getDirectCoachNextSessionTags = async () => {
      const beApi = apiAdapter();
      const logger = loggingAdapter();

      try {
        const res = await beApi.fetchAllAvailableSessionsBefore(
          onlyShowSessionTagsBefore(new Date())
        );

        const mapped = reduceSessionsToClassTagObjectForLookup(res);

        dispatch({
          type: ActionTypes.SET_DIRECT_COACH_NEXT_SESSION,
          payload: mapped,
        });
      } catch (error) {
        const errorCast = error as Error;
        logger.error({
          caller: 'Direct Coach Session Tag Global UseEffect',
          message: errorCast.message,
        });
      }
    };

    getDirectCoachNextSessionTags();
  }, [router, user.isLoggedIn]);

  return <>{children}</>;
};

export default DirectCoachEffects;
