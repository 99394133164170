import { setIsIntercomVisible } from '../adapters/intercomAdapter';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_VIDEO_URL = 'SET_VIDEO_URL',
  SET_VIDEO_PLAYING = 'SET_VIDEO_PLAYING',
  SET_VIDEO_MUTE = 'SET_VIDEO_MUTE',
  SET_VIDEO_VIEW_TYPE = 'SET_VIDEO_VIEW_TYPE',
  SET_VIDEO_FLOATING = 'SET_VIDEO_FLOATING',

  SET_VIDEO_STATUS = 'SET_VIDEO_STATUS',
  SET_VIDEO_PLAYED = 'SET_VIDEO_PLAYED',
  CLEAR_VIDEO = 'CLEAR_VIDEO',
}

export type State = {
  url?: string;
  viewType: 'inBrowser' | 'fullscreen';
  isPlaying: boolean;
  isMuted: boolean;
  isFloating: boolean;
  hasPlayed: boolean;
  status?: 'available' | 'unavailable' | 'isLoading';
};

type Payload = {
  [ActionTypes.SET_VIDEO_URL]: State['url'];
  [ActionTypes.SET_VIDEO_VIEW_TYPE]: State['viewType'];
  [ActionTypes.SET_VIDEO_PLAYING]: State['isPlaying'];
  [ActionTypes.SET_VIDEO_MUTE]: State['isMuted'];
  [ActionTypes.SET_VIDEO_FLOATING]: State['isFloating'];
  [ActionTypes.SET_VIDEO_PLAYED]: State['hasPlayed'];
  [ActionTypes.SET_VIDEO_STATUS]: 'available' | 'unavailable' | 'isLoading';
  [ActionTypes.CLEAR_VIDEO]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {
  url: undefined,
  viewType: 'inBrowser',
  isPlaying: false,
  isMuted: true,
  isFloating: false,
  hasPlayed: false,
  status: undefined,
};

export const reducer = (state: State, action: AllActions): State => {
  switch (action.type) {
    case ActionTypes.SET_VIDEO_URL:
      return { ...state, url: action.payload };

    case ActionTypes.SET_VIDEO_VIEW_TYPE:
      setIsIntercomVisible(action.payload !== 'inBrowser');
      return { ...state, viewType: action.payload };

    case ActionTypes.SET_VIDEO_PLAYING:
      return { ...state, isPlaying: action.payload };

    case ActionTypes.SET_VIDEO_MUTE:
      return { ...state, isMuted: action.payload };

    case ActionTypes.SET_VIDEO_FLOATING:
      return { ...state, isFloating: action.payload };

    case ActionTypes.SET_VIDEO_PLAYED:
      return { ...state, hasPlayed: action.payload };

    case ActionTypes.SET_VIDEO_STATUS:
      return { ...state, status: action.payload };

    case ActionTypes.CLEAR_VIDEO:
      return initialState;

    default:
      return state;
  }
};
