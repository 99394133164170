import { LoggingService } from './ports';

export type Log = {
  caller: string; // Where is the error coming from
  message: string; // What is the contense of the error message
};

export const loggingAdapter = (): LoggingService => {
  return {
    info(data: Log): void {
      console.log(JSON.stringify(data));
    },
    error(data: Log): void {
      try {
        if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost') {
          console.error(JSON.stringify(data));
        }
      } catch (err) {
        console.error(JSON.stringify(data));
      }
    },
    debug(data: Log): void {
      // only logg debug messages when running on local host
      if (process.env.NEXT_PUBLIC_LOG_LEVEL === 'debug') {
        try {
          if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost') {
            console.log(JSON.stringify(data));
          }
        } catch (err) {
          // catch any errors and just swallow them.
        }
      }
    },
  };
};
