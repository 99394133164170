import { Price } from '../backendDTOs';
import { State } from '../../context/filters';
import { User } from '../../domain/user';
import { LocalStorageKeys } from '../../domain/websiteDomain';
import Storage from './localStorageWrapper';

enum Locals {
  BML_STORAGE_PREFIX = 'BML-online-hub',
}

export default class LocalStorageHelper extends Storage<Locals> {
  private static instance?: LocalStorageHelper;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new LocalStorageHelper();
    }

    return this.instance;
  }

  private getDataWithKey(key: string): unknown | null {
    let data: any = {};
    const dataString = this.get(Locals.BML_STORAGE_PREFIX);

    if (dataString) {
      data = JSON.parse(dataString);
    }

    if (data && data[key]) {
      return data[key];
    }

    return null;
  }

  private setDataWithKey(newData: unknown, key: string): void {
    let data: any = {};
    const dataString = this.get(Locals.BML_STORAGE_PREFIX);

    if (dataString) {
      data = JSON.parse(dataString);
    }

    data[key] = newData;

    this.set(Locals.BML_STORAGE_PREFIX, JSON.stringify(data));
  }

  private deleteKey(key: string): void {
    let data: any = {};
    const dataString = this.get(Locals.BML_STORAGE_PREFIX);

    if (dataString) {
      data = JSON.parse(dataString);
    }

    delete data[key];

    this.set(Locals.BML_STORAGE_PREFIX, JSON.stringify(data));
  }

  public setUserData(user: User) {
    this.setDataWithKey(user, LocalStorageKeys.USER);
  }

  public getUserData(): User | null {
    const d = this.getDataWithKey(LocalStorageKeys.USER);

    if (d) {
      return d as User;
    } else {
      return null;
    }
  }

  public setCouponData(couponCode: string | string[]) {
    this.setDataWithKey(couponCode, LocalStorageKeys.COUPON);
  }

  public getCouponData() {
    return this.getDataWithKey(LocalStorageKeys.COUPON);
  }

  public clearCoupon() {
    this.deleteKey(LocalStorageKeys.COUPON);
  }

  public getFilterData(): State {
    return this.getDataWithKey(LocalStorageKeys.FILTERS) as State;
  }

  public setFilterData(filters: any) {
    this.setDataWithKey(filters, LocalStorageKeys.FILTERS);
  }

  public clearFilters() {
    this.deleteKey(LocalStorageKeys.FILTERS);
  }

  public clearUserData() {
    this.deleteKey(LocalStorageKeys.USER);
  }

  public clearAll() {
    this.clearItems([Locals.BML_STORAGE_PREFIX]);
  }

  public setPurchasedContract(contract: Price) {
    this.setDataWithKey(contract, LocalStorageKeys.CONTRACT);
  }

  public getPurchasedContract(): Price {
    return this.getDataWithKey(LocalStorageKeys.CONTRACT) as Price;
  }

  public clearContract() {
    this.deleteKey(LocalStorageKeys.CONTRACT);
  }
}
