import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { cookieAdapter } from '../../../adapters/cookieAdapter';
import LocalStorageHelper from '../../../adapters/helpers/localStorageHelper';
import { userService } from '../../../application/user';
import { AppContext, Types } from '../../../context';

interface AuthEffectsProps {}

const AuthEffects: React.FC<AuthEffectsProps> = ({ children }) => {
  const { dispatch } = useContext(AppContext);
  const router = useRouter();

  // on initial pageload, if user cookie is set then set in context
  // else attempt to fetch user
  useEffect(() => {
    const storageHelper = LocalStorageHelper.getInstance();
    const cookieAdapterL = cookieAdapter();

    const userFromStorage = storageHelper.getUserData();
    const authCookie = cookieAdapterL.getAuthCookie();

    if (userFromStorage) {
      dispatch({ type: Types.SET_USER, payload: userFromStorage });
      return;
    }

    if (authCookie) {
      const handleFetchUser = async () => {
        const applicationUser = userService();
        const u = await applicationUser.fetchUserWithSettingsOrUnauthedUser();

        dispatch({ type: Types.SET_USER, payload: u });
      };
      handleFetchUser();
      return;
    }
  }, [dispatch]);

  // if jwt is passed as query param then try and authenticate user
  // this occurs when an existing BML studio member accesses the online hub
  useEffect(() => {
    const user = userService();
    const cookieAdapterL = cookieAdapter();
    const authCookie = cookieAdapterL.getAuthCookie();

    const authenticateBmlUser = async (jwt: string) => {
      dispatch({ type: Types.IS_FETCHING, payload: true });
      // do the thing here
      await user.authenticateBMLUser(jwt);
      const u = await user.fetchUserWithSettingsOrUnauthedUser();

      dispatch({ type: Types.SET_USER, payload: u });
      dispatch({ type: Types.IS_FETCHING, payload: false });
    };

    const { jwt } = router.query;

    if (!authCookie && jwt && typeof jwt === 'string') {
      authenticateBmlUser(jwt);
    }
  }, [dispatch, router.query]);

  return <>{children}</>;
};

export default AuthEffects;
