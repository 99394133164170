import { ClassFragment } from '../typescript/generated/codegen';

export type Class = {
  imgURL: string;
  classification: string;
  className: string;
  slug: string;
  id: string;
};

export function isClassValid({
  imgURL,
  className,
}: Pick<Class, 'imgURL' | 'className'>): boolean {
  return !!imgURL && !!className;
}

export type SessionVideoCheck = {
  streamURL: string;
  status: string;
  streamURLs: StreamURL[];
};

export type StreamURL = {
  timeStamp: string;
  streamURL: string;
  status: string;
};

export const isVideoReadyToView = (videoCheckResult: SessionVideoCheck) =>
  videoCheckResult.status === 'ready';

export enum LevelName {
  LEVEL1 = 'Level 1',
  LEVEL12 = 'Level 1-2',
  LEVEL2 = 'Level 2',
  LEVEL23 = 'Level 2-3',
  LEVEL3 = 'Level 3',
}

export const BetterLevelName = {
  [LevelName.LEVEL1]: 'Beginner',
  [LevelName.LEVEL12]: 'Beginner',
  [LevelName.LEVEL2]: 'Intermediate',
  [LevelName.LEVEL23]: 'Intermediate',
  [LevelName.LEVEL3]: 'Advanced',
};

interface SessionFeedback {
  educatorId: string;
  className: string;
  userId?: string;
  email?: string;
  events?: string;
  educatorEmail?: string;
  percentWatched?: number;
  classid?: string;
}

export const generateFeedbackURL = (
  feedbackConfig: SessionFeedback
): string => {
  return encodeURI(
    `https://ik34idqyfpv.typeform.com/to/Ah9s2F00#classid=${feedbackConfig.classid}&user_id=${feedbackConfig.userId}&events=${feedbackConfig.events}&educator_id=${feedbackConfig.educatorId}&email=${feedbackConfig.email}&class_name=${feedbackConfig.className}&percent_watched=${feedbackConfig.percentWatched}`
  );
};

export const getSessionRoute = (session: ClassFragment) => {
  return `/teachers/${session.teacher?.slug}/${session.classification}/${session.id}`.toLowerCase();
};

export const getSessionTitle = (session: ClassFragment) =>
  `${session.name}${
    session.teacher
      ? ` with ${session.teacher.firstName} ${session.teacher.lastName}`
      : ''
  }`;

export const getSlideImage = (session: ClassFragment) => {
  if (session.image) return session.image;
  if (session.teacher?.heroImage) return session.teacher.heroImage;
  if (session.teacher?.image43) return session.teacher.image43;
  if (session.teacher?.image) return session.teacher.image;
  return undefined;
};
