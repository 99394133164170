import { User } from '../domain/user';
import { Price } from './backendDTOs';
import LocalStorageHelper from './helpers/localStorageHelper';
import { LocalStorageService } from './ports';

const _getUserData = (deps: {
  storageHelper: LocalStorageHelper;
}): User | null => {
  const { storageHelper } = deps;

  return storageHelper.getUserData();
};

const _setUserData = (
  data: User,
  deps: { storageHelper: LocalStorageHelper }
) => {
  const { storageHelper } = deps;

  storageHelper.setUserData(data);
};

export const _destroyUserData = (deps: {
  storageHelper: LocalStorageHelper;
}) => {
  const { storageHelper } = deps;

  storageHelper.clearUserData();
};

export const _getCouponData = (deps: { storageHelper: LocalStorageHelper }) => {
  const { storageHelper } = deps;

  return storageHelper.getCouponData();
};

const _setPurchasedContract = (
  contract: Price,
  deps: { storageHelper: LocalStorageHelper }
): void => {
  const { storageHelper } = deps;

  storageHelper.setPurchasedContract(contract);
};

const _getPurchasedContract = (deps: {
  storageHelper: LocalStorageHelper;
}): Price | undefined => {
  const { storageHelper } = deps;

  return storageHelper.getPurchasedContract();
};

const _destroyContractData = (deps: {
  storageHelper: LocalStorageHelper;
}): Price => {
  const { storageHelper } = deps;
  const storedContract = storageHelper.getPurchasedContract();

  storageHelper.clearContract();

  return storedContract;
};

export const localStorageAdapter = (): LocalStorageService => {
  const storageHelper = LocalStorageHelper.getInstance();

  return {
    getUserData: () => _getUserData({ storageHelper }),
    setUserData: (data: User) => _setUserData(data, { storageHelper }),
    destroyUserData: () => _destroyUserData({ storageHelper }),
    getCouponData: () => _getCouponData({ storageHelper }),
    setPurchasedContract: (contract: Price): void =>
      _setPurchasedContract(contract, { storageHelper }),
    getPurchasedContract: () => _getPurchasedContract({ storageHelper }),
    destroyContractData: () => _destroyContractData({ storageHelper }),
  };
};
