export const config = {
  title: 'BML Online Hub',
  description:
    'Get the studio experience from anywhere, anytime with a library of weekly fresh content designed to inspire your practice - both on and off your mat.',
  author: 'bodymindlife',
  url: process.env.NEXT_PUBLIC_APP_ROOT_DOMAIN,
  locale: 'en',
  contactDetails: [], // @TODO get contact details to be able to set CorporateContactJsonLd in DefaultSEO
  facebook: {
    url: 'https://www.facebook.com/BodyMindLife/',
  },
  instagram: {
    url: 'https://www.instagram.com/bodymindlife/?hl=en',
  },
  contact: {
    email: 'support@bodymindlife.online',
  },
};
