import { Asset, Sys, TeacherFragment } from '../typescript/generated/codegen';

export type Teacher = {
  displayName: string;
  image: string;
  slug: string;
};

export const getTeachersFullName = ({
  firstName,
  lastName,
}: Pick<TeacherFragment, 'firstName' | 'lastName'>) =>
  `${firstName} ${lastName}`;

export const isTeacherClassPage = (routerPath: string) => {
  return routerPath === '/teachers/[teacherName]/[classification]/[id]'
    ? true
    : false;
};
export const getTeacherRoute = (teacher: TeacherFragment) => {
  return `/teachers/${teacher?.slug}`;
};

export const getFirstName = (teacher: MarketingTeacher) =>
  teacher.name.split(' ')[0];

export interface MarketingTeacher {
  __typename: string;
  sys: Sys;

  /** full name */
  name: string;

  /** slug */
  slug: string;

  /** BEID */
  beid?: string;

  /** job title */
  position: string;

  /** profile image */
  profileImage: Asset;

  /** hero image */
  bannerImage: Asset;

  /** video */
  video?: Asset;

  /** bio */
  bio: string;

  /** qualifications */
  qualifications?: string;

  /** gallery photos */
  photosCollection?: {
    items: Asset[];
  };

  /** instagram link */
  instagram?: string;
  instagramHandle?: string;

  /** facebook link */
  facebook?: string;

  /** personal website link */
  website?: string;

  /** spotify profile link */
  spotifyProfile?: string;

  /** sportify playlist embed code */
  spotifyPlaylistEmbedCode?: string;
}
