import {
  LimitNotification,
  LimitNotificationSubscriptionModelType,
} from '../../domain/notifications';
import { CheckUserResponseDTO, MessageLimitOptionsDTO } from '../backendDTOs';

export const mapNotificationDTO = ({
  limitMessage,
  available,
}: CheckUserResponseDTO): LimitNotification | undefined => {
  switch (limitMessage) {
    // Set hours subscription
    case MessageLimitOptionsDTO.LimitExpired:
      return {
        id: MessageLimitOptionsDTO.LimitExpired,
        subscriptionModelType: LimitNotificationSubscriptionModelType.FixedHour,
        available,
      };
    case MessageLimitOptionsDTO.LimitToUpgrade:
      return {
        id: MessageLimitOptionsDTO.LimitToUpgrade,
        subscriptionModelType: LimitNotificationSubscriptionModelType.FixedHour,
        available,
      };

    // PAYG Subscription
    case MessageLimitOptionsDTO.LimitNotExpired:
      return {
        id: MessageLimitOptionsDTO.LimitNotExpired,
        subscriptionModelType: LimitNotificationSubscriptionModelType.PAYG,
        available,
      };
    case MessageLimitOptionsDTO.LimitTopUp:
      return {
        id: MessageLimitOptionsDTO.LimitTopUp,
        subscriptionModelType: LimitNotificationSubscriptionModelType.PAYG,
        available,
      };
    default:
      return;
  }
};
