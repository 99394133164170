import { CustomError } from 'ts-custom-error';
import { apiAdapter } from '../adapters/apiAdapter';
import { loggingAdapter } from '../adapters/loggingAdapter';
import { mapNotificationDTO } from '../adapters/mappers/notificationsMapper';
import { ApiService, LoggingService } from '../adapters/ports';
import {
  LimitNotification,
  shouldShowLimitNotification,
} from '../domain/notifications';
import { User } from '../domain/user';

export class NotificationApplicationError extends CustomError {
  public constructor(public type: string, message?: string) {
    super(message);
  }
}

export const _notificationApplicationErrorFactory = (
  options: { type: string; caller: string; message: string; err: any },
  defaultDeps: { logging: LoggingService }
): NotificationApplicationError => {
  const { logging } = defaultDeps;
  const { caller, message, err, type } = options;

  const error = new NotificationApplicationError(type, message);
  logging.error({
    caller: caller,
    message: `${message}: ${err}`,
  });
  logging.error({
    caller: `application.${caller}`,
    message: `Returning application layer error: ${error}`,
  });

  return error;
};

export const _getNewLimitNotification = async (
  user: User,
  defaultDeps: {
    api: ApiService;
    logging: LoggingService;
  }
): Promise<LimitNotification | undefined> => {
  const { api, logging } = defaultDeps;
  const caller = '_getNewNotification';

  if (!user.isLoggedIn) {
    return;
  }

  try {
    const checkResponse = await api.checkUser();

    const limitNotification = mapNotificationDTO(checkResponse);

    if (!limitNotification) {
      return;
    }

    if (shouldShowLimitNotification(limitNotification)) {
      return limitNotification;
    }

    return undefined;
  } catch (err) {
    throw _notificationApplicationErrorFactory(
      {
        type: 'GetNewNotificationsError',
        caller: caller,
        message: 'Failed to get new notification',
        err,
      },
      { logging }
    );
  }
};

export function notificationService() {
  const api: ApiService = apiAdapter();
  const logging: LoggingService = loggingAdapter();

  return {
    getNewLimitNotification: (
      user: User
    ): Promise<LimitNotification | undefined> =>
      _getNewLimitNotification(user, { api, logging }),
  };
}
