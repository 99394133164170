import { UrlObject } from 'url';

export enum Environment {
  Production = 'production',
  Staging = 'staging',
  Preview = 'preview',
  Local = 'local',
}

export const isServer = typeof window === 'undefined';

export type LinkOrButton = LinkModel | (() => void);

export const isLink = (input: LinkOrButton): input is LinkModel =>
  (input as LinkModel).href !== undefined;

export const isButton = (input: LinkOrButton): input is () => void =>
  (input as LinkModel).href === undefined;

export type LinkModel = {
  href: UrlObject | string;
  isExternal?: boolean;
};

export const getEnvironment = (): Environment => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return Environment.Production;
  }

  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' &&
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'stage'
  ) {
    return Environment.Staging;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
    return Environment.Preview;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') {
    return Environment.Local;
  }

  throw new Error('Could not find environment');
};

export const computeRootDomain = (): string => {
  const environment = getEnvironment();

  switch (environment) {
    case Environment.Production:
      return 'bodymindlife.online';
    case Environment.Staging:
      return 'bodymindlife.online'; // due to global cookie for auth purposes
    case Environment.Preview:
      return process.env.NEXT_PUBLIC_VERCEL_URL || 'vercel.app';
    case Environment.Local:
      return 'localhost';
    default:
      throw Error('Could not get Domain');
  }
};

export const computeFullDomain = (): string => {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Production:
      return 'app.bodymindlife.online';
    case Environment.Staging:
      return 'staging.app.bodymindlife.online'; // due to global cookie for auth purposes
    case Environment.Preview:
      return process.env.NEXT_PUBLIC_VERCEL_URL || 'vercel.app';
    case Environment.Local:
      return 'localhost';
    default:
      throw Error('Could not get Domain');
  }
};

export const computeDomainPrefix = () => {
  return getEnvironment() === Environment.Local ? 'http://' : 'https://';
};

export const computeFullDomainWithPrefix = () => {
  const baseUrl = `${computeDomainPrefix()}${computeFullDomain()}`;
  if (getEnvironment() === Environment.Local) {
    return `${baseUrl}:3000`;
  }

  return baseUrl;
};

export const computeRootDomainWithPrefix = () => {
  const baseUrl = `${computeDomainPrefix()}${computeRootDomain()}`;
  if (getEnvironment() === Environment.Local) {
    return `${baseUrl}:3000`;
  }

  return baseUrl;
};

export const generateReferralCodeLink = (
  baseURLWithoutSlash: string,
  promoCode: string
) => `${baseURLWithoutSlash + routes.SIGNUP}?coupon=${promoCode}`;

export const pageContainsClasses = (pathName: string) => {
  return [routes.HOME, routes.TEACHER_PROFILE, routes.CLASS_PAGE].includes(
    pathName
  );
};
export const homepageRoute = '/';
export const userProfileRoute = '/user/profile/';
export const teacherProfileRoute = '/teachers/[teacherName]';
export const marketingRoute = 'https://www.bodymindlife.online/';

export const routes = {
  HOME: homepageRoute,
  USER_PROFILE: userProfileRoute,
  USER_FAVOURITES: '/user/favourites',
  SIGNUP: '/user/signup/',
  LOGIN: '/user/login',
  GET_STARTED: '/user/get-started',
  RESET_PASSWORD: '/user/reset-password',
  SUBSCRIBE: '/user/subscribe',
  TEACHER_PROFILE: '/teachers/[teacherName]',
  SESSION_PAY: '/sessions/[id]/pay',
  CLASS_PAGE: '/teachers/[teacherName]/[classification]/[id]',
  FORGOT_PASSWORD: '/user/forgot-password',
  MARKETING_HOME: marketingRoute,
  PRICING: 'https://stagingbmlo.wpengine.com/pricing/',
  ABOUT_US: 'https://www.bodymindlife.online/about-us/',
  MARKETING_TEACHERS: 'https://www.bodymindlife.online/teachers/',
  MARKETING_EDUCATOR: 'https://www.bodymindlife.online/educator/',
  MARKETING_EDUCATOR_APPLY: 'https://www.bodymindlife.online/apply_now/',
  TERMS_AND_CONDITIONS: 'https://www.bodymindlife.online/terms-conditions/',
  PRIVACY_POLICY: 'https://www.bodymindlife.online/privacy-policy/',
  STUDENT_FAQ: 'https://www.bodymindlife.online/faqs/?tab=0',
  TEAMS_AT_WORK: 'https://www.bodymindlife.online/teams/',
  CONTACT_EMAIL: 'mailto:support@bodymindlife.online',
  YOGA_FOR_BEGINNERS: 'https://bodymindlife.online/yoga-for-beginners',
  PILATES_FOR_BEGINNERS: 'https://bodymindlife.online/pilates-for-beginners',
  EXPANSION_PROJECT: 'https://bodymindlife.online/the-expansion-project',
  LIVE_COMMUNITY_EVENTS:
    'https://www.bodymindlife.online/live-community-events/',
  GIFT_CARDS: 'https://www.bodymindlife.online/gift-cards/',
};

export const NextAPIRoutes = {
  FACEBOOK_EVENT: '/api/facebook/event',
};

export const generateAuthToken = (accessToken: string): string =>
  `Bearer ${accessToken}`;

export const NAVBAR_HEIGHT = 56;

export const TW_RING = `focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white focus:ring-accent-light`;

export enum CookieKeys {
  AUTH = 'bml.auth',
  ENVIRONMENT_AUTH = 'bml.environment-auth',
  COOKIES_ACCEPTED = 'bml.cookies-accepted',
}

export enum LocalStorageKeys {
  USER = 'bml.user',
  FILTERS = 'bml.filters',
  COUPON = 'bml.coupon-code',
  CONTRACT = 'bml.contract',
}

export enum DataStoreKeys {
  ONBOARDING_DATA = 'onboardingData',
  VIEWED = 'viewed',
  FAVOURITED = 'favourited',
}

export enum Breakpoints {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  '2XL' = 1536,
}

export const CAROUSEL_GUTTER_WIDTH =
  typeof window !== 'undefined'
    ? window.innerWidth > Breakpoints.LG
      ? 80
      : 20
    : 80;
