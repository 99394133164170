import { Log, loggingAdapter } from '../adapters/loggingAdapter';
import { LoggingService } from '../adapters/ports';

export const _logErrorMessage = (
  error: Log,
  defaultDeps: { logging: LoggingService }
): void => {
  const { logging } = defaultDeps;

  logging.error(error);
};

export const _logDebugMessage = (
  msg: any,
  defaultDeps: { logging: LoggingService }
): void => {
  const { logging } = defaultDeps;

  logging.debug(msg);
};

export function loggerService() {
  const logging: LoggingService = loggingAdapter();

  return {
    logErrorMessage: (error: Log): void => _logErrorMessage(error, { logging }),
    logDebugMessage: (msg: Log): void => _logDebugMessage(msg, { logging }),
  };
}
