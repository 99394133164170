import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { AiOutlineClose } from 'react-icons/ai';

const Toast = () => (
  <Toaster
    toastOptions={{
      duration: 1000000,
      style: {
        width: '800px',
        maxWidth: '800px',
      },
      className: 'toast-notification',
    }}
  >
    {t => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            {message}
            {t.type !== 'loading' && (
              <button id="toast-dismiss" onClick={() => toast.dismiss(t.id)}>
                <AiOutlineClose width={10} height={10} />
              </button>
            )}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
);

export default Toast;
