import { ContentfulClassCategories } from '../adapters/backendDTOs';
import LocalStorageHelper from '../adapters/helpers/localStorageHelper';
import { analyticsService } from '../application/analytics';
import { userService } from '../application/user';
import { User } from '../domain/user';
import { TeacherFragment } from '../typescript/generated/codegen';
import { ActionMap, AllActions } from './index';

declare const window: any;

export enum ActionTypes {
  IS_FETCHING = 'IS_FETCHING',
  SET_USER = 'SET_USER',
  SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA',
  SET_AVAILABLE_FILTERS = 'SET_AVAILABLE_FILTERS',
  SET_CLASSIFICATION_CATEGORIES = 'SET_CLASSIFICATION_CATEGORIES',
  SET_TEACHERS = 'SET_TEACHERS',
  LOGOUT = 'LOGOUT',
  ADD_LIMIT_NOTIFICATION_TO_BLACKLIST = 'ADD_LIMIT_NOTIFICATION_TO_BLACKLIST',
}

export type State = {
  isFetching: boolean;
  user?: User;
  onboarding?: { [key: string]: any };
  availableFilters: {
    type: { classification: string; value: string }[];
  };
  classificationCategories: ContentfulClassCategories[];
  teachers?: TeacherFragment[];
};

type Payload = {
  [ActionTypes.IS_FETCHING]: boolean;
  [ActionTypes.SET_USER]: User;
  [ActionTypes.SET_ONBOARDING_DATA]: { [key: string]: any };
  [ActionTypes.SET_AVAILABLE_FILTERS]: ContentfulClassCategories[];
  [ActionTypes.SET_CLASSIFICATION_CATEGORIES]: ContentfulClassCategories[];
  [ActionTypes.SET_TEACHERS]: TeacherFragment[];
  [ActionTypes.LOGOUT]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {
  isFetching: false,
  user: undefined,
  onboarding: undefined, // used to store onboarding data before user has been created
  availableFilters: { type: [] },
  classificationCategories: [],
  teachers: undefined,
};

export const reducer = (state: State, action: AllActions): State => {
  const storageHelper = LocalStorageHelper.getInstance();
  const analytics = analyticsService();
  const user = userService();

  switch (action.type) {
    case ActionTypes.IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case ActionTypes.SET_USER: {
      const user = action.payload;

      // manually add user information to the gtag data layer
      const data = user;
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        userId: data.id,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        userType: data.cType === 'Client' ? 'Student' : data.cType, //Educator or Student
        organisation: data.referral,
        country: data.country,
      });
      storageHelper.setUserData(user);

      return { ...state, user };
    }

    case ActionTypes.SET_ONBOARDING_DATA: {
      return { ...state, onboarding: action.payload };
    }

    case ActionTypes.LOGOUT: {
      if (state.user) analytics.userLogout(state.user);

      const unauthedUser = user.logoutUser();

      if (window.Intercom) window.Intercom('shutdown');

      return { ...state, user: unauthedUser };
    }

    case ActionTypes.SET_AVAILABLE_FILTERS: {
      // app reads filters as 1d array
      // i.e. [{classification: "yoga", type: "yin"]}, {classification: "yoga", type: "vinyasa"}]
      const f: any = action.payload.map(item => {
        return item.categories.map(cat => {
          return {
            classification: item.classification,
            value: cat,
          };
        });
      });
      // eslint-disable-next-line prefer-spread
      const filterItems: any = [].concat.apply([], f);

      return { ...state, availableFilters: { type: filterItems } };
    }

    case ActionTypes.SET_CLASSIFICATION_CATEGORIES: {
      const cc = action.payload.sort((a, b) => a.defaultIndex - b.defaultIndex);
      return { ...state, classificationCategories: cc };
    }

    case ActionTypes.SET_TEACHERS: {
      return { ...state, teachers: action.payload };
    }

    default:
      return state;
  }
};
