import { setIsIntercomVisible } from '../adapters/intercomAdapter';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_IS_FILTERS_OPEN = 'SET_IS_FILTERS_OPEN',
  SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN',
  SET_IS_TEACHER_REVIEW_OPEN = 'SET_IS_TEACHER_REVIEW_OPEN',
}

export type State = {
  isFiltersOpen: boolean;
  isMenuOpen: boolean;
  isTeacherReviewOpen: boolean;
};

type Payload = {
  [ActionTypes.SET_IS_FILTERS_OPEN]: boolean;
  [ActionTypes.SET_IS_MENU_OPEN]: boolean;
  [ActionTypes.SET_IS_TEACHER_REVIEW_OPEN]: boolean;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {
  isFiltersOpen: false,
  isMenuOpen: false,
  isTeacherReviewOpen: false,
};

export const reducer = (state: State, action: AllActions): State => {
  switch (action.type) {
    case ActionTypes.SET_IS_FILTERS_OPEN:
      setIsIntercomVisible(!action.payload);
      return { ...state, isFiltersOpen: action.payload };

    case ActionTypes.SET_IS_MENU_OPEN:
      setIsIntercomVisible(!action.payload);
      return { ...state, isMenuOpen: action.payload };

    case ActionTypes.SET_IS_TEACHER_REVIEW_OPEN:
      setIsIntercomVisible(!action.payload);
      return { ...state, isTeacherReviewOpen: action.payload };

    default:
      return state;
  }
};
