import { ClassFragment } from '../typescript/generated/codegen';
import { ActionMap, AllActions } from './index';

export enum ActionTypes {
  SET_CAROUSEL_IS_FETCHING = 'SET_CAROUSEL_IS_FETCHING',
  SET_CAROUSEL_DATA = 'SET_CAROUSEL_DATA',
}

export type State = {
  [id: string]: {
    isFetching: boolean;
    data?: ClassFragment[];
  };
};

type Payload = {
  [ActionTypes.SET_CAROUSEL_IS_FETCHING]: string;
  [ActionTypes.SET_CAROUSEL_DATA]: { id: string; data: ClassFragment[] };
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {};

export const reducer = (state: State, action: AllActions): State => {
  switch (action.type) {
    case ActionTypes.SET_CAROUSEL_IS_FETCHING:
      return {
        ...state,
        [action.payload]: { isFetching: true, data: undefined },
      };

    case ActionTypes.SET_CAROUSEL_DATA:
      return {
        ...state,
        [action.payload.id]: { isFetching: false, data: action.payload.data },
      };

    default:
      return state;
  }
};
