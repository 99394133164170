import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { loggerService } from '../../../application/logger';
import { notificationService } from '../../../application/notifications';
import { getMessage } from '../../../domain/notifications';
import { isTeacherClassPage } from '../../../domain/teacher';
import { User } from '../../../domain/user';
import useUserWithRedirects from '../../../hooks/useUser';

interface NotificationEffectsProps {}

const handleNotification = async (user: User) => {
  const applicationNotifications = notificationService();
  const logger = loggerService();

  if (user.isLoggedIn) {
    try {
      const newNotification =
        await applicationNotifications.getNewLimitNotification(user);

      if (newNotification) {
        toast(getMessage(newNotification));
      }
    } catch (err) {
      const errorCast = err as Error;
      logger.logErrorMessage({
        caller: 'handleNotifications',
        message: `Failed to fetch and dispatch new notifications: ${errorCast.message}`,
      });
    }
  }
};

const NotificationEffects: React.FC<NotificationEffectsProps> = ({
  children,
}) => {
  const user = useUserWithRedirects();
  const router = useRouter();

  useEffect(() => {
    if (isTeacherClassPage(router.pathname)) {
      handleNotification(user);
    }
  }, [router.pathname, user]);

  return <>{children}</>;
};

export default NotificationEffects;
