import {
  facebookAdapter,
  FacebookEventTypes,
} from '../adapters/facebookAdapter';
import { analyticsAdapter } from '../adapters/gtagAdapter';
import { localStorageAdapter } from '../adapters/localStorageAdapter';
import {
  FacebookPixelService,
  GoogleAnalyticsService,
  LocalStorageService,
} from '../adapters/ports';
import { User } from '../domain/user';
import { ClassFragment } from '../typescript/generated/codegen';

export const _recordPageView = (
  url: string,
  deps: { googleAnalytics: GoogleAnalyticsService }
): void => {
  const { googleAnalytics } = deps;

  googleAnalytics.recordPageView(url);
};

export const _logoutUser = (
  user: User,
  deps: { googleAnalytics: GoogleAnalyticsService }
) => {
  const { googleAnalytics } = deps;

  googleAnalytics.logout(user);
};

export const _successfulSubscription = (
  user: User,
  deps: {
    googleAnalytics: GoogleAnalyticsService;
    facebookPixel: FacebookPixelService;
    localStore: LocalStorageService;
  }
) => {
  const { googleAnalytics, facebookPixel, localStore } = deps;

  const purchasedContract = localStore.getPurchasedContract();

  localStore.destroyContractData();

  googleAnalytics.subscribeSuccess(user);
  facebookPixel.sendPurchase(user, purchasedContract);
};

export const _videoError = (
  session: ClassFragment,
  deps: { googleAnalytics: GoogleAnalyticsService }
) => {
  const { googleAnalytics } = deps;

  googleAnalytics.recordSessionVideoError(session);
};

export const _startVideo = (
  session: ClassFragment,
  deps: { googleAnalytics: GoogleAnalyticsService }
) => {
  const { googleAnalytics } = deps;

  googleAnalytics.recordSessionVideoError(session);
};

// want to differentiate between valid code, invaalid code, and failed request to check code
export enum GtagCouponCodeValues {
  VALID_CODE = 0,
  INVALID_CODE = 1,
  FAILED_REQUEST = 2,
}

export const recordCouponCode = (
  code: string,
  email: string,
  status: number,
  deps: { googleAnalytics: GoogleAnalyticsService }
) => {
  const { googleAnalytics } = deps;

  googleAnalytics.recordCouponCode(code, email, status);
};

export const _recordUserAccountCreation = (
  { email, firstName, lastName }: User,
  deps: { facebookPixel: FacebookPixelService }
): void => {
  const { facebookPixel } = deps;

  facebookPixel.sendFacebookEvent({
    email,
    firstName,
    lastName,
    eventName: FacebookEventTypes.LEAD,
  });
};

export function analyticsService() {
  const googleAnalytics: GoogleAnalyticsService = analyticsAdapter();
  const facebookPixel: FacebookPixelService = facebookAdapter();
  const localStore: LocalStorageService = localStorageAdapter();

  return {
    recordPageView: (url: string): void =>
      _recordPageView(url, { googleAnalytics }),
    userLogout: (user: User) => _logoutUser(user, { googleAnalytics }),
    successfulSubscription: (u: User) =>
      _successfulSubscription(u, {
        googleAnalytics,
        facebookPixel,
        localStore,
      }),
    recordSessionVideoError: (session: ClassFragment) =>
      _videoError(session, { googleAnalytics }),
    recordVideoStartWatch: (session: ClassFragment) =>
      _startVideo(session, { googleAnalytics }),
    recordCouponCode: (code: string, email: string, status: number) =>
      recordCouponCode(code, email, status, { googleAnalytics }),
    recordUserAccountCreation: (u: User): void =>
      _recordUserAccountCreation(u, { facebookPixel }),
  };
}
