import axios, { AxiosInstance } from 'axios';
import { CustomError } from 'ts-custom-error';
import { User } from '../domain/user';
import { NextAPIRoutes } from '../domain/websiteDomain';
import { Price } from './backendDTOs';
import { loggingAdapter } from './loggingAdapter';
import { FacebookPixelService, LoggingService } from './ports';

export type FacebookEventRequestDTO = {
  email?: string;
  firstName?: string;
  lastName?: string;
  eventName: FacebookEventTypes;
  currency?: string;
  value?: number;
  itemID?: string;
};

export enum FacebookEventTypes {
  LEAD = 'Lead',
  PURCHASE = 'Purchase',
}

export class FacebookAdapterError extends CustomError {
  public constructor(
    public statusCode: number,
    message?: string,
    public data?: unknown
  ) {
    super(message);
  }
}

export const _sendLeadEvent = async (
  req: FacebookEventRequestDTO,
  defaultDeps: { logger: LoggingService; fetcher: AxiosInstance }
) => {
  const { logger, fetcher } = defaultDeps;
  try {
    await fetcher.post(NextAPIRoutes.FACEBOOK_EVENT, req);
  } catch (err: any) {
    logger.error({
      caller: 'facebookAdapter._sendLeadEvent',
      message: `Failed to send facebook lead event: ${err.message}`,
    });
  }
};

const _sendPurchaseEvent = async (
  user: User,
  defaultDeps: { logger: LoggingService; fetcher: AxiosInstance },
  purchasedContract: Price | undefined
) => {
  const { logger, fetcher } = defaultDeps;
  const { email, firstName, lastName } = user;
  const {
    currency,
    metadata: { planName: itemID = '' } = {},
    unit_amount,
  } = purchasedContract ?? {};

  const value = unit_amount && unit_amount / 100;

  const req: FacebookEventRequestDTO = {
    email,
    firstName,
    lastName,
    eventName: FacebookEventTypes.PURCHASE,
    currency,
    itemID,
    value,
  };

  try {
    logger.info({
      caller: 'facebookAdapter._sendPurchaseEvent',
      message: `Sending facebook event with data: ${JSON.stringify(req)}`,
    });
    await fetcher.post(NextAPIRoutes.FACEBOOK_EVENT, req);
  } catch (err: any) {
    logger.error({
      caller: 'facebookAdapter._sendPurchaseEvent',
      message: `Failed to send facebook pixel purchase event: ${err.message}`,
    });
  }
};

const getFetcher = (): AxiosInstance => {
  return axios.create({
    headers: {
      // api key is available on public client, not super secure
      'x-api-key': process.env.NEXT_PUBLIC_SERVERLESS_API_KEY || '',
    },
  });
};

export const facebookAdapter = (): FacebookPixelService => {
  const logger: LoggingService = loggingAdapter();
  const fetcher: AxiosInstance = getFetcher();

  return {
    sendFacebookEvent: (req: FacebookEventRequestDTO): Promise<void> =>
      _sendLeadEvent(req, { logger, fetcher }),
    sendPurchase: (user: User, purchasedContract?: Price): Promise<void> =>
      _sendPurchaseEvent(user, { logger, fetcher }, purchasedContract),
  };
};
