import { MessageLimitOptionsDTO } from '../adapters/backendDTOs';

export type LimitNotification = {
  id: MessageLimitOptionsDTO;
  subscriptionModelType: LimitNotificationSubscriptionModelType;
  available: number;
};

export enum LimitNotificationSubscriptionModelType {
  PAYG = 'PAYG',
  FixedHour = 'FixedHour',
}

export const shouldShowLimitNotification = (
  limitNotification: Pick<LimitNotification, 'available'>
): boolean => limitNotification.available < 30;

export const getMessage = ({
  id,
  available,
}: Pick<LimitNotification, 'available' | 'id'>): string => {
  switch (id) {
    // Set hours subscription
    case MessageLimitOptionsDTO.LimitExpired: {
      return 'Your membership has expired. To upgrade your account, please contact support.';
    }
    case MessageLimitOptionsDTO.LimitToUpgrade: {
      return `You are in negative ${available} minutes. To upgrade your account, please contact support.`;
    }

    // PAYG Subscription
    case MessageLimitOptionsDTO.LimitNotExpired: {
      return `You have ${available} minutes left before your next topup.`;
    }

    case MessageLimitOptionsDTO.LimitTopUp: {
      return `You have zero minutes left and will be billed now to top up your account.`;
    }
    default: {
      throw new Error('could not get message for notification');
    }
  }
};
