import getUserLocale from 'get-user-locale';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { AppContext } from '../context';
import { getUnauthenticatedUser } from '../domain/user';

interface UseUser {
  redirectToIfFound?: string;
  redirectToIfNotFound?: string;
  redirectToIfNoActiveSubscription?: string;
  redirectToIfHasActiveSubscription?: string;
}

export default function useUserWithRedirects(args?: UseUser) {
  const redirectToIfFound = args?.redirectToIfFound;
  const redirectToIfNotFound = args?.redirectToIfNotFound;
  const redirectToIfHasActiveSubscription =
    args?.redirectToIfHasActiveSubscription;
  const redirectToIfNoActiveSubscription =
    args?.redirectToIfNoActiveSubscription;

  const { state } = useContext(AppContext);
  const router = useRouter();

  const user = state.settings.user;

  useEffect(() => {
    const redirectRequired =
      !!redirectToIfFound ||
      !!redirectToIfNotFound ||
      !!redirectToIfHasActiveSubscription ||
      !!redirectToIfNoActiveSubscription;

    // if no redirect needed, just return
    // if user data not yet there (fetch in progress) then don't do anything yet
    if (!redirectRequired || !user) return;

    if (
      redirectToIfHasActiveSubscription &&
      user.isLoggedIn &&
      user?.onlineAccess
    ) {
      router.push(redirectToIfHasActiveSubscription);
      return;
    }

    if (
      redirectToIfNoActiveSubscription &&
      user.isLoggedIn &&
      !user?.onlineAccess
    ) {
      router.push(redirectToIfNoActiveSubscription);
      return;
    }

    if (redirectToIfFound && user.isLoggedIn) {
      router.push(redirectToIfFound);
      return;
    }

    if (redirectToIfNotFound && !user.isLoggedIn) {
      router.push(redirectToIfNotFound);
      return;
    }
  }, [
    user,
    redirectToIfFound,
    redirectToIfNotFound,
    redirectToIfHasActiveSubscription,
    redirectToIfNoActiveSubscription,
    router,
  ]);

  return user || getUnauthenticatedUser(getUserLocale());
}
