import axios from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipAuthRefresh?: boolean;
  }
}

const getRecommenderFetcher = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_RECOMMENDER_ROOT_DOMAIN || '',
  });

  return axiosInstance;
};

export default getRecommenderFetcher;
