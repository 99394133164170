import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { NextRouter } from 'next/router';
import { BillingStatusID } from './billing';
import { DataStoreKeys } from './websiteDomain';

export type UserChecks = {
  status: string;
  videoAccess: boolean;
  accessStatus: string;
  billingModel: string;
  paymentPeriodEnd: Date;
  limit: string;
  available: number;
  limitMessage:
    | ''
    | 'LimitTopUp'
    | 'LimitExpired'
    | 'LimitToUpgrade'
    | 'LimitNotExpired';
};

export type Settings = {
  [DataStoreKeys.ONBOARDING_DATA]?: {
    [key: string]: any;
    hasSkipped?: boolean;
  };
  [DataStoreKeys.VIEWED]: {
    id: string;
    beid: string;
    viewedAt: string;
  }[];
  [DataStoreKeys.FAVOURITED]: {
    id: string;
    beid: string;
  }[];
};

export interface User {
  BMLRecord?: boolean;
  BMLToConvert?: boolean;
  BMLToConverted?: boolean;
  address?: string;
  address2?: string;
  billingStatus?: BillingStatusID;
  cType?: 'Client';
  city?: string;
  country?: string;
  created_at?: string;
  deleted_at?: string;
  email?: string;
  firstName?: string;
  homePhone?: string;
  id?: string;
  imageURL?: string;
  intercomCreatedAt?: number;
  intercomCreatedAtString?: string;
  intercomID?: string;
  lastName?: string;
  last_modified?: string;
  mobilePhone?: string;
  name?: string;
  onlineAccess?: boolean;
  postalCode?: string;
  promoCode?: string;
  referral?: string;
  sourceID?: string;
  state?: string;
  status?: 'active' | 'inactive' | 'deleted';
  stripeID?: string;
  teacherID?: string;
  workPhone?: string;

  settings?: Settings;
  locale: string;
  checks?: UserChecks;
  isLoggedIn: boolean;
}

export const isBMLStudioUser = (user: User) => user.BMLRecord;

export const getUnauthenticatedUser = (locale: string): User => {
  return {
    isLoggedIn: false,
    onlineAccess: false,
    locale,
  };
};

export const updateUserSettings = (user: User, settings: Settings): User => {
  const u = cloneDeep(user);
  u.settings = settings;

  return u;
};

export const updateUserLoggedIn = (user: User, isLoggedIn: boolean): User => {
  const u = cloneDeep(user);
  u.isLoggedIn = isLoggedIn;

  return u;
};

export const userDoesNotHaveSubscription = (user: User): boolean => {
  return user?.isLoggedIn && !user?.onlineAccess;
};

export const isUserLoggedIn = (user: User): boolean => {
  return user.isLoggedIn;
};

export const userHasSubscription = (user: User): boolean => {
  return !!(user?.isLoggedIn && user?.onlineAccess);
};

export const updateUserChecks = (user: User, userChecks: UserChecks): User => {
  const u = cloneDeep(user);
  const uc = cloneDeep(userChecks);
  u.checks = uc;

  return u;
};

export const canUserAccessSessionVideo = (user: User): boolean => {
  return user.checks?.videoAccess ? user.checks?.videoAccess : false;
};

export const getFullName = ({ firstName, lastName }: User): string => {
  return `${firstName} ${lastName}`;
};

export const getMonthAndYearJoined = (user: User): string => {
  if (!user.created_at) {
    throw new Error('User created at not found');
  }

  return dayjs(user.created_at).format('MMMM YYYY');
};

export const canUserSeeReferralLinks = (user: User) => {
  if (isBMLStudioUser(user)) {
    return false;
  }

  return true;
};

export const isUserAuthenticating = (router: NextRouter) =>
  [
    '/user/signup',
    '/user/login',
    '/user/profile',
    '/user/subscribe',
    '/user/subscribe/success',
    '/user/get-started',
  ].includes(router.pathname) || router.query?.subscribe === 'success';

export const showBMLToConvertAction = (user: User, router: NextRouter) => {
  return user.BMLToConvert && !isUserAuthenticating(router);
};

export const showSubscribeAction = (user: User, router: NextRouter) => {
  return (
    userDoesNotHaveSubscription(user) &&
    !isUserAuthenticating(router) &&
    !user.BMLToConvert
  );
};
