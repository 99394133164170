import { BookingForStudent, SessionByClassDC } from '../../domain/directCoach';
import {
  DirectCoachBookingResponseDTO,
  GetDCClassesResponseDTO,
} from '../backendDTOs';
import { dateRangeMapper } from './typeMappers';

export const directCoachResponseDTOtoDomain = (
  res: DirectCoachBookingResponseDTO
): BookingForStudent => ({
  bookingDC: {
    id: res.Booking.id,
  },
  sessionDC: {
    classLengthInSeconds: res.Booking.duration,
    dateRange: dateRangeMapper(
      res.Booking.startDateTime,
      res.Booking.endDateTime
    ),
    id: res.OTS.id,
    isCancelled: res.Booking.cancelled,
  },
  classBML: {
    classification: res.Class.classification,
    imgURL: res.Class.imageURL,
    className: res.Class.name,
    slug: res.Class.shortID,
  },
  teacher: {
    displayName: res.Teacher.firstName + ' ' + res.Teacher.lastName,
  },
});

export const getSessionsByClassResponseDTOtoSessionByClass = (
  be: GetDCClassesResponseDTO
): SessionByClassDC => ({
  hasBooked: false, // little scummy, but don't want to go to crazy with aggregates

  session: {
    costInDollars: be.price,
    dateRange: dateRangeMapper(be.startDateTime, be.endDateTime),
    id: be.id,
    numberBooked: be.visitBooked,
    numberLimit: be.maxParticipants,
    isCancelled: be.isCanceled,
  },
  teacher: {
    displayName: be.teacherName,
  },
  class: {
    id: be.classID,
  },
});
